import { Database } from "supabase/generated/types";
import { NotificationType } from "types/tables";

export function getNotificationText(
  notification_type: NotificationType,
  attached_deal_comment_type:
    | Database["public"]["Enums"]["deal_comment_type"]
    | null,
  body?: string
) {
  let preText = "";
  if (notification_type === "LikedYourPost") {
    preText = "Liked your post in";
  } else if (notification_type === "PostedAComment") {
    if (attached_deal_comment_type === "ANNOUNCEMENT") {
      preText = "Posted an announcement in";
    } else {
      preText = "Posted a comment in";
    }
  } else if (notification_type === "ExpressedInterestInYourDeal") {
    preText = "Expressed interest in your deal: ";
  } else if (notification_type === "ReferredYouToANewDeal") {
    preText = "Invited you to a new deal: ";
  } else if (notification_type === "IsASuggestedSponsorConnection") {
    preText =
      "Is a suggested connection. Send them a connection request to get access to their future deals.";
  } else if (notification_type === "HasAcceptedYourConnectionRequest") {
    preText = "Is now connected with you.";
  } else if (notification_type === "SentYouAConnectionRequest") {
    preText = "Sent you a connection request.";
  } else if (notification_type === "WroteYouAnEndorsement") {
    preText = "Wrote you an endorsement";
  } else if (notification_type === "AddedYouAsATeamMember") {
    preText = "Added you as a team member in the organization";
  } else if (notification_type === "HasJustBeenReferredToYourDeal") {
    preText = "Has just been referred to your deal: ";
  }
  return {
    preText,
    body,
  };
}
