import { StorageError } from "@supabase/storage-js";
import { clientSupabase } from "www/shared/modules/supabase";
import { ProfileFormInputType } from "./EditProfileModal";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
let hostUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;

interface ErrorResponse {
  data: null;
  error: StorageError;
}
interface SuccessResponse {
  data: {
    path: string;
  };
  error: null;
}
type StorageResponse = SuccessResponse | ErrorResponse;

export const updateProfile = async ({
  formData,
  profilePhotoLink,
  coverPhotoLink,
  profileImage,
  coverImage,
  userId,
}: {
  formData: ProfileFormInputType;
  profilePhotoLink: string | null | undefined;
  coverPhotoLink: string | null | undefined;
  profileImage: File | null | undefined;
  coverImage: File | null | undefined;
  userId: string;
}) => {
  let profilePictureUrl = profilePhotoLink;
  let coverPhotoUrl = coverPhotoLink;
  let profileImagePath = `users/${userId}/profile_pic_${uuidv4()}.png`;
  let coverImagePath = `users/${userId}/cover_photo_${uuidv4()}.png`;
  const uploadProfilePics = async (profileImage: File) =>
    await clientSupabase.storage
      .from("public")
      .upload(`${profileImagePath}`, profileImage, {
        cacheControl: "1",
        upsert: true,
      });
  const uploadCoverImage = async (profileImage: File) =>
    await clientSupabase.storage
      .from("public")
      .upload(`${coverImagePath}`, profileImage, {
        cacheControl: "1",
        upsert: true,
      });
  let [profilePicsUploadResponse, CoverImageUploadResponse] = await Promise.all(
    [
      profileImage
        ? uploadProfilePics(profileImage)
        : Promise.resolve<StorageResponse | undefined>(undefined),
      coverImage
        ? uploadCoverImage(coverImage)
        : Promise.resolve<StorageResponse | undefined>(undefined),
    ]
  );
  if (profilePicsUploadResponse?.error) {
    console.log("error", profilePicsUploadResponse.error);
    return;
  }
  if (CoverImageUploadResponse?.error) {
    console.log("error", CoverImageUploadResponse.error);
    return;
  }
  if (profilePicsUploadResponse?.data) {
    profilePictureUrl = `${hostUrl}/storage/v1/object/public/public/${profileImagePath}`;
  }
  if (CoverImageUploadResponse?.data) {
    coverPhotoUrl = `${hostUrl}/storage/v1/object/public/public/${coverImagePath}`;
  }

  const { error, count } = await clientSupabase
    .from("user_profiles")
    .update(
      {
        cover_photo_url: coverPhotoUrl,
        profile_pic_url: profilePictureUrl,
        first_name: formData.first_name
          ? _.capitalize(formData.first_name)
          : formData.first_name,
        last_name: formData.last_name
          ? _.capitalize(formData.last_name)
          : formData.last_name,
        handle: formData.handle?.toLowerCase(), // converting to lowercase, while Ajay adds a unique constraint on the handle column
        subtitle: formData.subtitle,
        about: formData.about,
        facebook_url: formData.facebook_url,
        instagram_url: formData.instagram_url,
        linkedin_url: formData.linkedin_url,
        twitter_url: formData.twitter_url,
        phone_number: formData.phone_number,
      },
      { count: "exact" }
    )
    .eq("user_id", userId);

  if (error) {
    throw error;
  }

  if (count !== 1) {
    throw Error("No rows were found to update.");
  }
};
