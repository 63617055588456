import { ReactNode } from "react";
import { TopLoadingBar } from "../TopLoadingBar";
import { usePathname } from "next/navigation";
import { Footer } from "./Footer";
import BlogPostAlertBanner from "./BlogPostAlertBanner";

export default function MainLayout({
  children,
  navBar,
}: {
  children: ReactNode;
  navBar?: ReactNode;
}) {
  const path = usePathname();
  const isInvestorDashboardPage = !!path && path.includes("/dashboard");

  return (
    <div className="h-full min-h-screen w-full bg-white text-gray-700 flex flex-col antialiased">
      <BlogPostAlertBanner />
      {path !== "/" && path !== "/sponsors" && path !== "/investors" && navBar}
      {!isInvestorDashboardPage && <TopLoadingBar />}
      <div className="flex flex-grow justify-center bg-gray-100">
        {children}
      </div>
      <Footer />
      {/* {path !== "/" && path !== "/sponsors" && path !== "/investors" && (
        <div className="fixed bottom-4 left-4">
          <AccountSetupPopover />
        </div>
      )} */}
    </div>
  );
}
