import { Notification } from "types/views";
import EndorsementNotificationCard from "./notification_types/EndorsementNotification";
import RequestNotificationCard from "./notification_types/RequestNotification";
import TeamNotificationCard from "./notification_types/TeamNotification";
import DealNotificationCard from "./notification_types/DealNotification";

export default function NotificationCardSelector({
  notifications,
  isInMenu,
}: {
  notifications: Notification[];
  isInMenu?: boolean;
}) {
  return (
    <>
      {notifications.map((notification, index: number) => {
        switch (notification.notification_type) {
          case "LikedYourPost":
          case "PostedAComment":
          case "ExpressedInterestInYourDeal":
          case "ReferredYouToANewDeal":
          case "IsASuggestedSponsorConnection":
            return (
              <DealNotificationCard
                key={index}
                notification={notification}
                isInMenu={isInMenu}
              />
            );
          case "HasAcceptedYourConnectionRequest":
            return (
              <RequestNotificationCard
                key={index}
                notification={notification}
                isInMenu={isInMenu}
              />
            );
          case "SentYouAConnectionRequest":
            return (
              <RequestNotificationCard
                key={index}
                notification={notification}
                isInMenu={isInMenu}
              />
            );
          case "AddedYouAsATeamMember":
            return (
              <TeamNotificationCard
                key={index}
                notification={notification}
                isInMenu={isInMenu}
              />
            );
          case "WroteYouAnEndorsement":
            return (
              <EndorsementNotificationCard
                key={index}
                notification={notification}
                isInMenu={isInMenu}
              />
            );
          case "HasJustBeenReferredToYourDeal":
            return (
              <DealNotificationCard
                key={index}
                notification={notification}
                isInMenu={isInMenu}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}
