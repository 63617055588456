import axios, { AxiosResponse } from "axios";
import { SignUpWithEmailAndPasswordRequestBody } from "pages/api/signup_with_email_and_password";

export const signupWithEmailAndPassword: ({
  email,
  password,
  first_name,
  last_name,
  phone_number,
}: SignUpWithEmailAndPasswordRequestBody) => Promise<{
  data: {
    status: string;
  };
}> = async ({
  email,
  password,
  first_name,
  last_name,
  phone_number,
}: SignUpWithEmailAndPasswordRequestBody) =>
  axios
    .post(`/api/signup_with_email_and_password`, {
      email,
      password,
      first_name,
      last_name,
      phone_number,
    })
    .then(
      ({
        data,
      }: AxiosResponse<{
        data: {
          status: string;
        };
      }>) => data
    )
    .catch((err) => {
      throw err;
    });
