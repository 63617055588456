import { QueryClient } from "react-query";
import { DealPageView } from "types/views";
import { clientSupabase } from "www/shared/modules/supabase";
export enum SearchQueryKey {
  SearchView = "SearchView",
}

export const searchDeals = async ({ query }: { query: string }) => {
  const response = await clientSupabase
    .from("deal_page_for_cur_user_view")
    .select(`title, deal_images, unique_share_link, is_active, handle`)
    .ilike("title", `%${query}%`)
    .returns<
      Pick<
        DealPageView,
        "title" | "deal_images" | "unique_share_link" | "is_active" | "handle"
      >[]
    >();

  if (response.error) throw response.error;

  return response;
};

export const searchUsers = async ({
  query,
  userId,
}: {
  query: string;
  userId: string;
}) => {
  const response = await clientSupabase
    .from("friends_list_view")
    .select("*")
    .or(`first_name.ilike.%${query}%, last_name.ilike.%${query}%`)
    .eq("from_user_id", userId);

  if (response.error) throw response.error;
  return response;
};

// combine both fetchers into one
export const search = async ({
  query,
  userId,
}: {
  query: string;
  userId: string | undefined;
}) => {
  const [deals, users] = await Promise.all([
    searchDeals({ query }),
    userId
      ? searchUsers({ query: query, userId: userId })
      : Promise.resolve(null),
  ]);
  return { deals, users };
};

export const invalidateSearchViews = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(SearchQueryKey.SearchView);
};
