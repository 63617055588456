import { useEffect } from "react";
import Link from "next/link";
import Avatar from "../Avatar";
import {
  useGlobalState,
  useProcessLogout,
} from "www/shared/modules/global_context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import DropdownMenu from "./DropdownMenu";
import { useRouter } from "next/router";
import NotificationMenu from "./NotificationMenu";
import { Menu, Transition } from "@headlessui/react";
import SearchBox from "./SearchBox";
import LoginOrSignupModal from "../auth_modal/LoginOrSignupModal";
import EditProfileModal from "www/pages/profile/EditProfileModal";
import { useQuery } from "react-query";
import {
  fetchUnreadConversationsCount,
  NotificationQueryKey,
  fetchNotifications,
} from "./NotificationMenu.fetchers";
import { Fragment, useState, forwardRef } from "react";
import { classNames } from "www/shared/utils";
import NotificationNumber from "./NotificationNumber";
import { homeFeedStep, welcomeStep } from "www/pages/deal/TourProvider";
import Image from "next/image";
import { indianDomain } from "www/shared/utils/handleDomains";
import Container from "../container/Container";
import {
  NavBarQueryKey,
  getUserSignUpDetailsFromOrgContact,
} from "./NavBar.fetchers";
import {
  faBell,
  faChartMixed,
  faHouse,
  faMessageDots,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { PlatinumStatusModal } from "../profile/PlatinumStatusModal";
import { useWindowSize } from "react-use";

interface NavLinkProps {
  label: string;
  href: string;
  isDisabled?: boolean;
}

export function NavBar() {
  const router = useRouter();
  const {
    supabaseUser,
    userProfile,
    isAuthModalOpen,
    isUpdateModalOpen,
    authModalVariant,
    setGlobalState,
  } = useGlobalState();

  const setOpenAuthModal = (open: boolean) => {
    setGlobalState({ isAuthModalOpen: open });
  };
  const setUpdateModalOpen = (open: boolean) => {
    setGlobalState({ isUpdateModalOpen: open });
  };

  const [isPlatinumStatusModalOpen, setIsPlatinumStatusModalOpen] =
    useState(false);

  const public_routes: string[] = [
    "/deal/[handle]/[access]",
    "/deal/discover/[handle]/[access]",
    "/deal/[handle]/e/[email_hash]",
  ];
  const isReferralPageOnly = public_routes.includes(router.route);

  const toEmail = router.query.toEmail as string;
  const dealHandle = getDealHandleFromUrl(router.asPath);

  const { data: userContactDetails } = useQuery({
    queryKey: [NavBarQueryKey.GET_USER_SIGNUP_DETAILS_FROM_ORG_CONTACT],
    queryFn: () =>
      getUserSignUpDetailsFromOrgContact({
        dealHandle: dealHandle!,
        email: toEmail,
      }),
    enabled: !!dealHandle && !!toEmail && !!isReferralPageOnly,
  });

  return (
    <>
      {!supabaseUser && (
        <LoginOrSignupModal
          open={isAuthModalOpen}
          setOpen={setOpenAuthModal}
          variant={authModalVariant}
          firstName={userContactDetails?.first_name}
          lastName={userContactDetails?.last_name}
          userEmail={toEmail}
        />
      )}
      {userProfile && (
        <EditProfileModal
          profile={userProfile}
          isOpen={isUpdateModalOpen}
          setIsOpen={setUpdateModalOpen}
          referral={true}
        />
      )}
      <PlatinumStatusModal
        setIsPlatinumStatusModalOpen={setIsPlatinumStatusModalOpen}
        isPlatinumStatusModalOpen={isPlatinumStatusModalOpen}
        userId={userProfile?.cur_user_id!}
        isPlatinumStatusUser={userProfile?.is_platinum_status ?? false}
      />
      <header className="bg-white border-b border-b-slate-200 min-h-full py-4 pb-2">
        <Container>
          <nav className="flex justify-between items-center">
            <NavBarLogoAndSearch />
            <NavBarLinks
              pathname={router.pathname}
              setIsPlatinumStatusModalOpen={setIsPlatinumStatusModalOpen}
            />
          </nav>
        </Container>
      </header>
    </>
  );
}

const getDealHandleFromUrl = (url: string) => {
  const regex = /\/deal\/([^\\/]+)\//;
  const match = url.match(regex);
  if (match) {
    return match[1];
  }
};

export const MobileNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => {
    let { href, label, isDisabled, ...rest } = props;

    if (isDisabled) {
      return (
        <button
          className="text-gray-300 text-base font-medium"
          disabled={true}
          {...rest}
        >
          {label}
        </button>
      );
    }
    return (
      <Link
        href={href}
        ref={ref}
        className="text-base font-medium text-gray-500"
        {...rest}
      >
        {label}
      </Link>
    );
  }
);

const NavBarLink = ({
  icon,
  label,
  href,
  isActive,
  notificationCount,
  isShowingNotification,
  isMenuButton,
  isDisabled,
}: {
  icon: IconDefinition;
  label: string;
  href: string;
  isActive?: boolean;
  notificationCount?: number;
  isShowingNotification?: boolean;
  isMenuButton?: boolean;
  isDisabled?: boolean;
}) => {
  if (isDisabled) {
    return (
      <button className="flex flex-col items-center gap-0.5 w-20 text-gray-300" disabled>
        <FontAwesomeIcon icon={icon} className="w-6 !h-6" />
        <span className="text-xs font-normal">{label}</span>
      </button>
    );
  }
  const LinkElement = () => (
    <div
      className={classNames(
        "flex flex-col items-center gap-0.5 w-20 cursor-pointer",
        isActive ? "text-gray-700 border-b-2 border-gray-600" : "text-gray-500"
      )}
    >
      {isShowingNotification ? (
        <span className="flex relative items-start">
          <FontAwesomeIcon icon={icon} className="w-6 !h-6" />
          <NotificationNumber amount={notificationCount} />
        </span>
      ) : (
        <FontAwesomeIcon icon={icon} className="w-6 !h-6" />
      )}
      <span className="text-xs font-normal">{label}</span>
    </div>
  );

  if (!!isMenuButton) {
    return (
      <Menu.Button disabled={isDisabled}>
        <LinkElement />
      </Menu.Button>
    );
  }
  return (
    <Link href={href} legacyBehavior>
      <div>
        <LinkElement />
      </div>
    </Link>
  );
};

export const NavBarLogoAndSearch = () => {
  const { width } = useWindowSize();
  const isMobileView = width <= 1024;
  const currentDomain = typeof window !== "undefined" && window.location.host;
  const supabaseUser = useGlobalState((s) => s.supabaseUser);
  return (
    <div className="flex items-center gap-4">
      <div className="lg:pr-0 md:pr-6 pr-4">
        {/* Website Logo */}
        <div className="relative  flex-shrink-0">
          <Link
            href={supabaseUser ? "/feed" : "/"}
            data-tour={homeFeedStep}
            legacyBehavior
          >
            <a className="flex items-center gap-4">
              {currentDomain == indianDomain ? (
                <h1 className=" text-2xl leading-8 font-semibold text-green-700">
                  <div data-tour={welcomeStep}>
                    <Image
                      src={supabaseUser ? "/logo_in_v2.svg" : "/logoIn.svg"}
                      alt="Logo"
                      width={supabaseUser ? 50 : 140}
                      height={50}
                    />
                  </div>
                </h1>
              ) : (
                <>
                  <div className="h-10 w-10" data-tour={welcomeStep}>
                    <Image
                      src="/logo_v2.svg"
                      alt="Logo"
                      width={40}
                      height={40}
                      className="w-10 h-10 min-w-10 min-h-10 max-w-10 max-h-10"
                    />
                  </div>
                  {!supabaseUser && !isMobileView && (
                    <h1 className=" text-2xl leading-8 font-semibold text-green-700">
                      ELMBASE
                    </h1>
                  )}
                </>
              )}
            </a>
          </Link>
        </div>
      </div>
      <SearchBox />
    </div>
  );
};

export const NavBarLinks = ({
  pathname,
  setIsPlatinumStatusModalOpen,
  setIsNotificationSettingsSlideOverOpen,
}: {
  pathname: string;
  setIsPlatinumStatusModalOpen: (open: boolean) => void;
  setIsNotificationSettingsSlideOverOpen?: (open: boolean) => void;
}) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isMobileNotificationMenuOpen, setIsMobileNotificationMenuOpen] =
    useState(false);

  useEffect(() => {
    document.body.style.overflow = isMobileNavOpen ? "hidden" : "unset";
  }, [isMobileNavOpen]);

  const processLogout = useProcessLogout();
  const { supabaseUser, userProfile, sponsorDeals, setGlobalState } =
    useGlobalState();

  const { data: unseenMessagesCount } = useQuery({
    queryKey: [NotificationQueryKey.UnreadInboxConversations, supabaseUser?.id],
    queryFn: () => fetchUnreadConversationsCount(supabaseUser?.id!),
    onError: (err) => {
      console.log("err", err);
    },
    refetchInterval: 1000 * 60, // refetch every minute
    refetchIntervalInBackground: false,
    enabled: !!supabaseUser?.id,
  });

  // Grabbing the number of unread notifications
  const { data: res } = useQuery({
    queryKey: [NotificationQueryKey.NotificationView, supabaseUser?.id],
    queryFn: () => fetchNotifications(supabaseUser?.id!),
    onError: (err) => {
      console.log("err", err);
    },
    enabled: !!supabaseUser?.id,
  });

  const unSeenNotifications =
    res?.data?.notifications?.filter((n) => n.is_seen === false).length ||
    0 +
      (res?.data?.connection_requests?.filter((n) => n.is_seen === false)
        .length || 0);

  const toggleMobileNav = () => {
    setIsMobileNavOpen((prev) => {
      // document.body.classList.toggle("overflow-hidden", !prev);
      return !prev;
    });
  };

  const toggleMobileNotificationMenu = () => {
    setIsMobileNotificationMenuOpen((prev) => {
      // document.body.classList.toggle("overflow-hidden", !prev);
      return !prev;
    });
    // document.body.classList.toggle("overflow-hidden", isMobileNavOpen);
  };

  let generalMobileNavLinks = [
    {
      label: "Home",
      url: "/feed",
      isDisabled: false,
    },
    {
      label: "Messages",
      url: "/inbox",
      isDisabled: !supabaseUser,
    },
    {
      label: "Your Profile",
      url: `/p/${userProfile?.handle}`,
      isDisabled: !supabaseUser,
    },
    {
      label: "Deal Dashboard",
      url: "/dashboard/considering",
      isDisabled: !supabaseUser,
    },
  ];
  const adminDashboardLink = {
    label: "Admin Dashboard",
    url: "/account/deals",
    isDisabled: !supabaseUser,
  };

  generalMobileNavLinks =
    supabaseUser && sponsorDeals.length > 0
      ? [...generalMobileNavLinks, adminDashboardLink]
      : generalMobileNavLinks;

  const portfolioDashboardLink = {
    label: "Portfolio Dashboard",
    url: `/dashboard`,
    isDisabled: !supabaseUser,
  };

  const settingsLink = {
    label: "Account Settings",
    url: "/account",
    isDisabled: !supabaseUser,
  };

  const mobileNavLinks = [
    ...generalMobileNavLinks,
    portfolioDashboardLink,
    settingsLink,
  ];

  return (
    <>
      <div className="hidden md:flex items-center lg:space-x-6 space-x-5 lg:pl-0 md:pl-6 pl-4 flex-shrink-0">
        <NavBarLink
          icon={faHouse}
          label="Home"
          href="/feed"
          isActive={pathname === "/feed"}
        />
        <NavBarLink
          icon={faChartMixed}
          label="Portfolio"
          href="/dashboard"
          isActive={pathname.includes("/dashboard")}
          isDisabled={!supabaseUser}
        />
        <NavBarLink
          icon={faMessageDots}
          label="Messages"
          href="/inbox"
          notificationCount={unseenMessagesCount}
          isShowingNotification={
            unseenMessagesCount !== null && unseenMessagesCount !== 0
          }
          isActive={pathname.startsWith("/inbox")}
          isDisabled={!supabaseUser}
        />
        <Menu as="div" className="relative inline-block text-left ">
          <>
            <div>
              <NavBarLink
                icon={faBell}
                label="Notifications"
                isMenuButton
                isShowingNotification={
                  unSeenNotifications !== null && unSeenNotifications !== 0
                }
                notificationCount={unSeenNotifications}
                href=""
                isDisabled={!supabaseUser}
              />
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-[-75px] z-50 mt-2 pt-1.5 w-[400px] h-[620px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll scrollbar">
                <NotificationMenu notificationsViewData={res?.data} />
              </Menu.Items>
            </Transition>
          </>
        </Menu>
        {supabaseUser ? (
          <DropdownMenu
            logout={() => processLogout()}
            setIsPlatinumStatusModalOpen={setIsPlatinumStatusModalOpen}
            setIsNotificationSettingsSlideOverOpen={
              setIsNotificationSettingsSlideOverOpen
            }
          />
        ) : (
          <button
            className="flex flex-col items-center gap-0.5 w-20 cursor-pointer text-gray-500"
            onClick={() =>
              setGlobalState({
                isAuthModalOpen: true,
                authModalVariant: "login",
              })
            }
          >
            <div className="flex w-6 h-6 pt-[7px] px-1 justify-center items-center rounded-full bg-gray-100 overflow-hidden">
              <FontAwesomeIcon
                icon={faUser}
                className="text-lg leading-[19px]"
              />
            </div>
            <span className="text-xs font-normal">Login</span>
          </button>
        )}
      </div>
      {/* Mobile Menu */}
      <section className="flex md:hidden pl-4">
        <div onClick={toggleMobileNav}>
          {/* Mobile Menu Button */}
          <span className="sr-only">Open Menu</span>
          {isMobileNavOpen ? (
            <FontAwesomeIcon
              icon={faXmark}
              className="hover:text-gray-500 text-gray-400 w-6 h-6"
            />
          ) : (
            <>
              <div className="inline-flex w-full justify-center bg-transparent mr-1.5 focus:outline-none relative cursor-pointer">
                <FontAwesomeIcon
                  icon={faBars}
                  className="hover:text-gray-500 text-gray-400 w-6 h-6"
                />
                {unseenMessagesCount !== null &&
                  unSeenNotifications !== null &&
                  (unseenMessagesCount !== 0 || unSeenNotifications !== 0) && (
                    <NotificationNumber
                      amount={unseenMessagesCount! + unSeenNotifications}
                    />
                  )}
              </div>
            </>
          )}
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={isMobileNavOpen}
        >
          <div
            className={classNames(
              isMobileNavOpen
                ? "flex fixed w-full h-screen  top-0 left-0 bg-white z-10 flex-col overflow-y-hidden"
                : "hidden invsible"
            )}
          >
            <div className="py-6 px-5">
              <FontAwesomeIcon
                icon={faXmark}
                className="hover:text-gray-500 text-gray-400 w-6 h-6 cursor-pointer"
                onClick={toggleMobileNav}
              />
            </div>
            {/* Top Section */}
            <ul className="flex flex-col justify-between px-4 mb-4">
              {/* Will need to eventually modify this section when a Notifications Page has been created */}
              <li className={pathname == "/" ? "active" : ""}>
                <button
                  className={classNames(
                    "mb-1 flex items-center justify-between rounded-md w-full py-2 px-4 font-medium",
                    supabaseUser ? "text-gray-700" : "text-gray-300"
                  )}
                  onClick={toggleMobileNotificationMenu}
                  disabled={!supabaseUser}
                >
                  <div>Notifications</div>
                  <div className="text-xs font-bold">
                    {unSeenNotifications !== null &&
                      unSeenNotifications !== 0 && (
                        <span>{unSeenNotifications} New</span>
                      )}
                  </div>
                </button>
              </li>
              <li onClick={toggleMobileNav}>
                <Link href={supabaseUser ? "/inbox" : "#"} legacyBehavior>
                  <button
                    className={classNames(
                      "flex justify-between items-center rounded-md w-full py-2 px-4 font-medium",
                      pathname.startsWith("/inbox") ? "bg-gray-100" : "",
                      supabaseUser ? "text-gray-700" : "text-gray-300"
                    )}
                    disabled={!supabaseUser}
                  >
                    <div>Inbox</div>
                    <div className="text-xs font-bold">
                      {supabaseUser &&
                        unseenMessagesCount !== null &&
                        unseenMessagesCount !== 0 && (
                          <span>{unseenMessagesCount} Unread</span>
                        )}
                    </div>
                  </button>
                </Link>
              </li>
            </ul>

            <hr />
            {/* Bottom Section */}
            <ul
              className="flex flex-col justify-between min-h-[45%] px-8 mt-4"
              onClick={toggleMobileNav}
            >
              {!!supabaseUser && (
                <li className="flex">
                  <Avatar
                    src={userProfile?.profile_pic_url!}
                    className="rounded-full object-cover"
                    firstName={userProfile?.first_name}
                    lastName={userProfile?.last_name}
                    size={40}
                  />

                  <div className="flex flex-col ml-[8px]">
                    <div className="text-sm leading-5 font-medium text-gray-900">
                      {userProfile?.first_name} {userProfile?.last_name}
                    </div>
                    <div className="text-xs leading-4 font-medium text-gray-500">
                      {userProfile?.is_sponsor === true
                        ? "Sponsor"
                        : "Investor"}
                    </div>
                  </div>
                </li>
              )}
              {mobileNavLinks.map((link, index) => (
                <li key={index}>
                  <MobileNavLink
                    href={link.url}
                    label={link.label}
                    isDisabled={link.isDisabled}
                  />
                </li>
              ))}
              {/* {setIsNotificationSettingsSlideOverOpen !== undefined && (
                <li>
                  <button
                    className="text-gray-500 text-base font-medium"
                    onClick={() => setIsNotificationSettingsSlideOverOpen(true)}
                  >
                    Notification Settings
                  </button>
                </li>
              )} */}
              <li
                className="text-gray-500 text-base font-medium"
                onClick={() => {
                  if (supabaseUser) {
                    processLogout();
                  } else {
                    setGlobalState({
                      isAuthModalOpen: true,
                      authModalVariant: "login",
                    });
                  }
                }}
              >
                <span>{supabaseUser ? "Sign Out" : "Login"}</span>
              </li>
              {!!supabaseUser && (
                <Link
                  href={"https://elmbase.canny.io/feature-requests"}
                  legacyBehavior
                >
                  <li>
                    <button className="rounded-md w-full py-2 px-4 bg-green-700 font-medium text-white hover:text-gray-100 disabled:bg-green-900">
                      Share Feedback
                    </button>
                  </li>
                </Link>
              )}
            </ul>
          </div>
        </Transition>
        {isMobileNotificationMenuOpen ? (
          <section className="flex fixed inset-0 w-full h-screen bg-white z-10 flex-col overflow-y-hidden md:hidden">
            {/* Header */}
            <div className="min-h-[70px] flex items-center pl-4">
              <FontAwesomeIcon
                icon={faXmark}
                className="hover:text-gray-500 text-gray-400 w-6 h-6"
                onClick={toggleMobileNotificationMenu}
              />
              <p className="ml-[16px] text-lg leading-7 font-medium text-gray-900">
                Notifications
              </p>
            </div>
            <NotificationMenu
              onNotificationClick={() => {
                toggleMobileNav();
                toggleMobileNotificationMenu();
              }}
              notificationsViewData={res?.data}
            />
          </section>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

MobileNavLink.displayName = "MobileNavLink";
