import * as yup from "yup";
const urlRegex = /^[a-zA-Z0-9_-]+\.*$/;

export const editProfileModalSchema = yup
  .object({
    handle: yup
      .string()
      .typeError("Handle is reqired.")
      .label("Handle")
      .max(100)
      .test({
        name: "handle",
        exclusive: true,
        message: "Use a valid URL.",
        test(value, ctx) {
          if (!urlRegex.test(value!)) {
            return ctx.createError({
              message: "Kindly supply a URL friendly handle.",
            });
          }
          return true;
        },
      })
      .required("Handle is required"),
    first_name: yup
      .string()
      .typeError("First name is required.")
      .label("First name")
      .max(100)
      .required("First name is required."),
    last_name: yup
      .string()
      .typeError("Last name is required.")
      .label("Last name")
      .max(100)
      .required("Last name is required."),
    subtitle: yup
      .string()
      .typeError("Subtitle is required.")
      .label("Subtitle")
      .max(100)
      .required("Subtitle is required."),
    phone_number_required: yup.boolean(),
    phone_number: yup
      .string()
      .typeError("Phone number is required.")
      .label("Phone number")
      .max(100)
      .when("phone_number_required", {
        is: (phone_number_required: boolean) => phone_number_required,
        then: yup
          .string()
          .required(
            "Please provide your phone number to complete the registration process and access deals on Elmbase."
          ),
        otherwise: yup.string().nullable(),
      }),
  })
  .required();
