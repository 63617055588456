import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { Database } from "supabase/generated/types";

if (
  !process.env.NEXT_PUBLIC_SUPABASE_URL ||
  !process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
) {
  throw new Error(
    "Need to set supabase creds" + process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
  );
}

const supabaseUrl: string = process.env.NEXT_PUBLIC_SUPABASE_URL;
const supabaseAnonKey: string = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;
export const clientSupabase = createPagesBrowserClient<Database>({
  supabaseUrl: supabaseUrl,
  supabaseKey: supabaseAnonKey,
});
