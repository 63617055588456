import { Database } from "supabase/generated/types";
export type CountryEnum = Database["public"]["Enums"]["country"];
type CountryDomains = {
  [key in CountryEnum]: string[];
};
const defaultDomain = "elmbase.com";
export const indianDomain = "elmbase.in";

export type ElmbaseHostedDomains = "elmbase.com" | "elmbase.in";
export const domains: CountryDomains = {
  India: [indianDomain],
  USA: [defaultDomain, "localhost"],
};

export const getUserDomain = (
  countries: CountryEnum[] | null
): ElmbaseHostedDomains => {
  if (!countries || countries.length === 0 || countries.includes("USA")) {
    return defaultDomain;
  } else if (countries.includes("India")) {
    return indianDomain;
  } else {
    return defaultDomain;
  }
};

export const shouldBypassDomainCheck = (domain: string): boolean => {
  const isPreview = domain.includes(".netlify.app");
  const isVercelPreview = domain.includes(".vercel.app");
  const isLocalhost = domain.includes("localhost");
  const isStaging = domain.includes("staging.elmbase.com");
  return isPreview || isLocalhost || isStaging || isVercelPreview;
};
