import React from "react";
import classNames from "www/shared/utils/classNames";
import ElementWithTooltip from "../ElementWithTooltip";
export interface TextAreaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string | React.ReactNode;
  placeholder?: string;
  value?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  info?: string;
  labelClassName?: string;
  inputClassName?: string;
  containerClassName?: string;
  required?: boolean;
  rows?: number;
  tooltipInfo?: {
    id: string;
    title?: string;
    description?: string;
  };
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

function TextArea(props: TextAreaProps, ref: React.Ref<HTMLTextAreaElement>) {
  const {
    label,
    info,
    error,
    rows,
    placeholder,
    required,
    errorMessage,
    containerClassName,
    name,
    className,
    inputClassName,
    tooltipInfo,
    ...rest
  } = props;
  
  return (
    <div className={containerClassName}>
      <div className="sm:items-start sm:gap-4 ">
        {label && (
          <label
            htmlFor={name}
            className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 flex items-center gap-1"
          >
            {label} {required && <span className="text-red-500">*</span>}{" "}
            {tooltipInfo && (
              <ElementWithTooltip uniqueId={tooltipInfo.id}>
                {tooltipInfo.title && (
                  <div className="text-sm font-medium text-white mb-2">
                    {tooltipInfo.title}
                  </div>
                )}
                <span className="text-sm font-normal text-gray-200">
                  {tooltipInfo.description}
                </span>
              </ElementWithTooltip>
            )}
          </label>
        )}
        <div className={classNames(label ? "mt-2.5" : "", "w-full")}>
          <textarea
            name={name}
            rows={rows}
            className={classNames(
              "block w-full rounded-lg border-gray-200 focus:border-indigo-500-700 focus:ring-indigo-500 sm:text-sm placeholder:text-gray-500 text-gray-900 shadow-sm scrollbar",
              className ? className : "",
              inputClassName ? inputClassName : "",
              error
                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500"
                : ""
            )}
            defaultValue={""}
            placeholder={placeholder}
            {...rest}
            ref={ref}
          />
          {info && <p className="mt-2 text-sm text-gray-500">{info}</p>}
          {error && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef<HTMLTextAreaElement, TextAreaProps>(TextArea);
