/**
 * NOTE: This requires `@sentry/nextjs` version 7.3.0 or higher.
 *
 * This page is loaded by Nextjs:
 *  - on the server, when data-fetching methods throw or reject
 *  - on the client, when `getInitialProps` throws or rejects
 *  - on the client, when a React lifecycle method throws or rejects, and it's
 *    caught by the built-in Nextjs error boundary
 *
 * See:
 *  - https://nextjs.org/docs/basic-features/data-fetching/overview
 *  - https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 *  - https://reactjs.org/docs/error-boundaries.html
 */
"use client";
import * as Sentry from "@sentry/nextjs";
import type { NextPage } from "next";
import type { ErrorProps } from "next/error";
import NextErrorComponent from "next/error";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useGlobalState } from "www/shared/modules/global_context";

const CustomErrorComponent: NextPage<ErrorProps> = ({ title, statusCode }) => {
  const supabaseUser = useGlobalState((s) => s.supabaseUser);
  const currentPath = usePathname();

  // If you're using a Nextjs version prior to 12.2.1, uncomment this to
  // compensate for https://github.com/vercel/next.js/issues/8592
  // Sentry.captureUnderscoreErrorException(props);
  if (!supabaseUser) {
    title = "Please login to continue.";
  } else if (statusCode === 404 && !title) {
    title = "This page could not be found.";
  } else if (!title) {
    title =
      "An error occurred. Please logout and log back in and try again. If the issue persists, please contact support at team@elmbase.com.";
  }

  return (
    <div className="flex flex-col flex-grow gap-3 items-center justify-center h-screen">
      {!supabaseUser && (
        <div className="flex flex-col gap-3 items-center justify-center space-y-1">
          <h1 className="text-xl">Please login to continue.</h1>
          <Link href={`/?login=true&redirectTo=${currentPath}`} legacyBehavior>
            <button className="bg-green-700 rounded-md text-white px-5 py-2.5 text-sm leading-5 font-semibold">
              Login
            </button>
          </Link>
        </div>
      )}
      {supabaseUser && (
        <>
          <h1 className="text-3xl">{statusCode}</h1>
          <h4 className="text-xl">{title}</h4>
        </>
      )}
    </div>
  );
};

CustomErrorComponent.getInitialProps = async (contextData) => {
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(contextData);

  // This will contain the status code of the response
  return NextErrorComponent.getInitialProps(contextData);
};

export default CustomErrorComponent;
