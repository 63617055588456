import { clientSupabase } from "www/shared/modules/supabase";
import { PortfolioForCurUserView } from "./Dashboard.types";
import { QueryClient } from "react-query";

export enum PortfolioDashboardQueryKey {
  Portfolio = "Portfolio",
}

export const fetchUserPortfolio = async ({ userId }: { userId: string }) => {
  const { data: portfolio, error: portfolioDataError } = await clientSupabase
    .from("portfolio_for_cur_user_view")
    .select()
    .eq("owner_user_id", userId)
    .single<PortfolioForCurUserView>();
  if (portfolioDataError) {
    throw portfolioDataError;
  }
  return portfolio;
};

export const invalidatePortfolioQuery = ({
  queryClient,
  portfolioId,
}: {
  queryClient: QueryClient;
  portfolioId: number;
}) => {
  queryClient.invalidateQueries([
    PortfolioDashboardQueryKey.Portfolio,
    portfolioId,
  ]);
};

export const savePortfolioNotes = async ({
  notes,
  portfolioId,
  dealGroup,
}: {
  notes: string;
  portfolioId: number;
  dealGroup: "Invested" | "Considering" | "Passed" | "Concluded"
}) => {
  const notesUpdateKey =
    dealGroup === "Invested"
      ? "portfolio_notes"
      : `${dealGroup.toLowerCase()}_deals_notes`;
  const { error } = await clientSupabase
    .from("portfolios")
    .update(
      {
        [notesUpdateKey]: notes,
      }
    )
    .eq("id", portfolioId);
  if (error) {
    throw error;
  }
}
