import { Footer } from "./Footer";
import BlogPostAlertBanner from "./BlogPostAlertBanner";
import { ReactNode } from "react";

export default function EmailDesignLayout({ children }: { children: ReactNode }) {
  return (
    <div className="h-full min-h-screen w-full bg-white text-gray-700 flex flex-col antialiased">
      <BlogPostAlertBanner />
      {children}
      <Footer />
      {/* {path !== "/" && (
        <div className="fixed bottom-4 left-4">
          <AccountSetupPopover />
        </div>
      )} */}
    </div>
  );
}
