import React, { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { useQuery } from "react-query";
import { search, SearchQueryKey } from "./SearchBox.fetchers";
import _ from "lodash";
import { getFullName } from "www/shared/utils";
import { useRouter } from "next/navigation";
import classNames from "www/shared/utils/classNames";
import Skeleton from "react-loading-skeleton";
import { createBlurUrl, toBase64 } from "www/shared/utils/imageUtils";
import { useGlobalState } from "www/shared/modules/global_context";
import Avatar from "../Avatar";
import Image from "next/image";

export default function SearchBox() {
  const router = useRouter();
  const [query, setQuery] = useState("");
  const containerRef = React.useRef<HTMLDivElement>(null);
  const supabaseUser = useGlobalState((s) => s.supabaseUser);

  const {
    data: searchResult,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: [SearchQueryKey.SearchView, query],
    queryFn: () => search({ query: query, userId: supabaseUser?.id }),
    onError: (err) => {
      console.log("err", err);
    },
    enabled: false,
  });

  // use debounce to trigger reftch for search
  const goSearch = _.debounce(() => {
    refetch();
  }, 300);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    goSearch();
  };

  return (
    <section className="w-full">
      <div className="w-full relative" ref={containerRef}>
        <Combobox
          onChange={(href: string) => {
            router.push(href);
          }}
        >
          {({ open }) => (
            <>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"></path>
                  </svg>
                </div>
                <Combobox.Input
                  className={classNames(
                    "block w-full rounded-t-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-gray-300 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none sm:text-sm focus:ring-transparent",
                    open ? "rounded-t-md rounded-b-none" : "rounded-md"
                  )}
                  placeholder={`Search deals${supabaseUser ? ", sponsors" : ""}`}
                  displayValue={() => query}
                  onChange={onChange}
                  autoComplete="off"
                />
              </div>
              <Transition
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="absolute left-0 right-0 z-[999999999999] overflow-hidden rounded-b-md bg-white shadow-2xl w-full md:w-[650px]">
                  {isFetching ||
                  (!searchResult?.users && !searchResult?.deals) ? (
                    <div className="flex flex-col pb-3">
                      {supabaseUser && (
                        <>
                          <Combobox.Label className="text-gray-500 text-xs font-semibold bg-gray-100 border-b border-gray-200 w-full p-1 pl-3">
                            Users
                          </Combobox.Label>
                          <div className="space-y-3 flex flex-col ml-2">
                            <SkeletonLoading type="user" />
                            <SkeletonLoading type="user" />
                            <SkeletonLoading type="user" />
                          </div>
                        </>
                      )}
                      <Combobox.Label className="text-gray-500 text-xs font-semibold bg-gray-100 border-b border-gray-200 w-full p-1 pl-3">
                        Deals
                      </Combobox.Label>
                      <div className="space-y-3 flex flex-col ml-2">
                        <SkeletonLoading type="deal" />
                        <SkeletonLoading type="deal" />
                        <SkeletonLoading type="deal" />
                      </div>
                    </div>
                  ) : (
                    (searchResult?.users || searchResult?.deals) && (
                      <Combobox.Options
                        static
                        className="w-full h-full flex flex-col max-h-72 md:max-h-96 lg:max-h-[562px] scroll-py-2 overflow-y-auto pb-3"
                      >
                        <>
                          {!isFetching && searchResult?.users?.data && (
                            <>
                              <Combobox.Label className="text-gray-500 text-xs font-semibold bg-gray-100 border-b border-gray-200 w-full p-1 pl-3">
                                Users
                              </Combobox.Label>
                              {searchResult.users.data.length > 0 && (
                                <div className="mb-2">
                                  {searchResult.users.data.map(
                                    (user, index) => (
                                      <Combobox.Option
                                        className="cursor-default select-none"
                                        value={`/p/${user.handle}`}
                                        key={index + "" + user.handle}
                                      >
                                        <UserRowCard
                                          key={index}
                                          firstName={user.first_name}
                                          lastName={user.last_name}
                                          avatar={user.profile_pic_url}
                                        />
                                      </Combobox.Option>
                                    )
                                  )}
                                </div>
                              )}
                              {searchResult.users.data.length === 0 && (
                                <p className="text-xs leading-4 font-normal text-gray-500 px-2 mt-3 mb-4 ml-2">
                                  No results in your connections
                                </p>
                              )}
                            </>
                          )}
                          {!isFetching && searchResult?.deals?.data && (
                            <>
                              <Combobox.Label className="text-gray-500 text-xs font-semibold bg-gray-100 border-b border-gray-200 w-full p-1 pl-3">
                                Deals
                              </Combobox.Label>
                              {searchResult.deals.data.length > 0 && (
                                <>
                                  <div className="mb-2">
                                    {searchResult.deals.data.map(
                                      (deal, index) => (
                                        <Combobox.Option
                                          className="cursor-default select-none"
                                          value={
                                            deal.is_active
                                              ? `/deal/${deal.handle}/${deal.unique_share_link}`
                                              : `/deal/${deal.handle}`
                                          }
                                          key={index}
                                        >
                                          <DealRowCard
                                            key={index}
                                            title={deal.title!}
                                            status={deal.is_active}
                                            // sort deal_images by order index
                                            dealImage={
                                              deal.deal_images?.sort(
                                                (a, b) =>
                                                  (a.order_index || 0) -
                                                  (b.order_index || 0)
                                              )[0]?.image_url || null
                                            }
                                          />
                                        </Combobox.Option>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                              {searchResult.deals.data.length === 0 && (
                                <p className="text-xs leading-4 font-normal text-gray-500 px-2 mt-3 mb-1 ml-2">
                                  No results in your connections
                                </p>
                              )}
                            </>
                          )}
                        </>
                      </Combobox.Options>
                    )
                  )}
                </div>
              </Transition>
            </>
          )}
        </Combobox>
      </div>
    </section>
  );
}

type UserRowCardProps = {
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
  connected?: boolean;
};
const UserRowCard = ({
  firstName,
  lastName,
  avatar,
  connected,
}: UserRowCardProps) => {
  return (
    <div className="flex items-center gap-2 hover:bg-gray-100 p-2 ml-2 group/item cursor-pointer">
      <Avatar
        size={32}
        src={avatar!}
        firstName={firstName!}
        lastName={lastName!}
        placeholder="blur"
        blurDataURL={`data:image/svg+xml;base64,${toBase64(
          createBlurUrl(32, 32)
        )}`}
      />
      <div className="flex justify-between w-full">
        <p className="text-sm leading-5 font-normal text-gray-900 flex items-center gap-2">
          {getFullName({
            firstName: firstName!,
            lastName: lastName,
          })}
        </p>
        <span className="flex items-center gap-4 ">
          {connected && (
            <p className="text-xs leading-4 font-semibold text-gray-700">
              Connected
            </p>
          )}
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-gray-500 w-[10px] h-2 invisible group-hover/item:visible"
          />
        </span>
      </div>
    </div>
  );
};

type DealRowCardProps = {
  title: string;
  status: boolean | null;
  dealImage: string | null;
};
const DealRowCard = ({ title, status, dealImage }: DealRowCardProps) => {
  return (
    <div className="flex items-center gap-2 hover:bg-gray-100 p-2 ml-2 group/item cursor-pointer">
      <Image
        src={dealImage ?? "/deal.png"}
        height={32}
        width={32}
        className="rounded-lg h-8 w-8 min-w-8 max-w-8 min-h-8 max-h-8"
        alt="avatar"
      />
      <div className="flex justify-between w-full">
        <span className="flex gap-2 items-center justify-between w-full">
          <p className="text-sm leading-5 font-normal text-gray-900">{title}</p>
          {!status && (
            <span className="text-xs leading-4 font-medium rounded-lg border border-gray-600 text-gray-600 px-2 py-0.5 min-w-fit">
              Deal Closed
            </span>
          )}
        </span>
        <span className="flex items-center gap-4 invisible group-hover/item:visible">
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-gray-500 w-[10px] h-2"
          />
        </span>
      </div>
    </div>
  );
};

const SkeletonLoading = ({ type }: { type: "deal" | "user" }) => (
  <div className="flex items-center gap-2 hover:bg-gray-100 p-2 group/item cursor-pointer w-full">
    {type === "deal" && (
      <Skeleton height={32} width={32} className="rounded-lg h-8 w-8" />
    )}
    {type === "user" && (
      <Skeleton
        height={32}
        width={32}
        circle
        className="rounded-full h-8 w-8"
      />
    )}
    <Skeleton
      height={24}
      className="w-full bg-red-500"
      style={{ width: "100%" }}
      containerClassName="flex-1"
    />
  </div>
);

export { SkeletonLoading };
