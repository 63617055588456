import Link from "next/link";
import React from "react";
import { Notification } from "types/views";
import { getFullName } from "www/shared/utils";
import NotificationCardContainer from "../NotificationCardContainer";

export default function EndorsementNotificationCard({
  notification,
  isInMenu,
}: {
  notification: Notification;
  isInMenu?: boolean;
}) {
  return (
    <Link href={`/p/${notification.handle}`} legacyBehavior>
      <div className="cursor-pointer">
        <NotificationCardContainer
          id={notification.id}
          profilePicUrl={notification.profile_pic_url}
          name={getFullName({
            firstName: notification.first_name ?? "",
            lastName: notification.last_name,
          })}
          time={notification.created_at}
          isSeen={notification.is_seen}
          handle={`/p/${notification.handle}`}
          isInMenu={isInMenu}
        >
          <div className="text-sm text-gray-500">
            {/* TODO:Endorsement message to come at a later date */}
            <p className="inline">Wrote you an endorsement</p>{" "}
          </div>
        </NotificationCardContainer>
      </div>
    </Link>
  );
}
