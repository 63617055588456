import { clientSupabase } from "./supabase";

export const properlyOrderUsers = (user_id_1: string, user_id_2: string) => {
  return user_id_1 < user_id_2
    ? [user_id_1, user_id_2]
    : [user_id_2, user_id_1];
};

export const getConnection = async ({
  user_id_1,
  user_id_2,
}: {
  user_id_1: string;
  user_id_2: string;
}) => {
  const [from_user_id, to_user_id] = properlyOrderUsers(user_id_1, user_id_2);
  const { data, error } = await clientSupabase
    .from("connections")
    .select()
    .eq("from_user_id", from_user_id)
    .eq("to_user_id", to_user_id);

  if (error) {
    throw error;
  }

  return data?.[0];
};
