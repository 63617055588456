import { useIsFetching } from "react-query";
import styles from "./loading.module.css";

export function TopLoadingBar() {
  const isFetching = useIsFetching();

  return (
    <div className="w-full flex justify-center max-h-0.5 bg-gray-100">
      <div className={`${isFetching ? styles.loading_bar : ""} h-0.5`}></div>
    </div>
  );
}
