import { FC, ButtonHTMLAttributes } from "react";
import { VariantProps, cva } from "class-variance-authority";
import { Spinner } from "../spinner/Spinner";

const buttonVariants = cva(
  "text-sm px-3 py-2 rounded-md flex justify-center items-center gap-x-2 transition-colors",
  {
    variants: {
      variant: {
        filled: "text-white font-semibold bg-green-700 hover:bg-green-800",
        filledWhite: "text-slate-900 font-semibold bg-white",
        outlined:
          "font-normal bg-transparent border hover:bg-green-800 hover:text-white",
        disabledOutlined: "font-normal bg-transparent border opacity-30",
        disabled: "text-white font-semibold bg-emerald-700/30",
        filledRed:
          "text-red-700 font-semibold border border-red-700 bg-red-100",
        filledBlack: "text-white font-semibold bg-black",
      },
      outlineColor: {
        green: "text-green-700 border-green-700",
        gray: "text-gray-700 border-gray-300",
      },
      width: {
        fit: "w-fit",
        full: "w-full",
      },
    },
    defaultVariants: {
      variant: "filled",
      outlineColor: "green",
    },
  }
);

interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = ({
  className,
  variant,
  width,
  outlineColor,
  isLoading,
  ...props
}) => {
  return (
    <button
      className={buttonVariants({
        className,
        variant,
        width,
        outlineColor,
      })}
      {...props}
    >
      {isLoading ? (
        <Spinner color={outlineColor === "green" ? "green" : "white"} />
      ) : (
        props.children
      )}
    </button>
  );
};

export { Button, buttonVariants };
