import classNames from "classnames";

interface NotificationNumberProps {
  amount?: number;
}

export default function NotificationNumber({
  amount,
}: NotificationNumberProps) {
  if (!amount) return null;

  // if the amount is greater than 9, we show "9+" instead, and need to shift the number a bit to the left to compensate
  const amountStr = amount > 9 ? "9+" : amount;
  const rightOffset = amount > 9 ? "-right-3" : "-right-2";

  return (
    <span
      className={classNames(
        "absolute ring-1 ring-white bg-green-700 rounded-full text-white text-xs font-normal flex items-center justify-center px-1 -top-1 min-w-[16px]",
        rightOffset
      )}
    >
      {amountStr}
    </span>
  );
}
