import { TourProvider } from "@reactour/tour";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";
import { useGlobalState } from "www/shared/modules/global_context";

// Deal tour steps here
export const welcomeStep = "welcomeStep";
export const dealInfoStep = "dealInfoStep";
export const sponsorStep = "sponsorStep";
export const inviteStep = "inviteStep";
export const indicateInterestStep = "indicateInterestStep";
export const discussionStep = "discussionStep";
export const homeFeedStep = "homeFeedStep";
export const finalStep = "finalStep";
const dealTourSteps = [
  {
    selector: `[data-tour="${welcomeStep}"]`,
    content: `Welcome aboard! Elmbase is designed to be used with others. 
    Invite your friends to collaboratively evaluate deals together and connect on Elmbase. 
    And the more you engage — the more deals will be presented to you.`,
  },
  {
    selector: `[data-tour="${dealInfoStep}"]`,
    content: `Here's the deal that's being presented to you.
    Make sure to do your own due diligence before investing.`,
  },
  {
    selector: `[data-tour="${sponsorStep}"]`,
    content: `You can check out the sponsor's profile and click into the sponsor's organization here.`,
  },
  {
    selector: `[data-tour="${inviteStep}"]`,
    content: `Invite your friends and collaboratively evaluate the deal together.`,
  },
  {
    selector: `[data-tour="${indicateInterestStep}"]`,
    content: `If you'd like to express interest in this deal and see your next steps, click "Indicate interest". Connect with your friends on Elmbase to see who else in your network has expressed interest.`,
    // highlight the button (circled in red)
  },
  {
    selector: `[data-tour="${discussionStep}"]`,
    content: `Check out any announcements and frequently asked questions here.`,
  },
  {
    selector: `[data-tour="${homeFeedStep}"]`,
    content: `Click on the Elmbase logo to go to your home feed to keep up with the latest on deals in your network.`,
  },
  {
    selector: `[data-tour="${finalStep}"]`,
    content: `You can always return back to this guided tour by clicking this button. Enjoy Elmbase!`,
  },
];

// Inbox tour steps here
export const dealRoomWelcome = "dealRoomWelcome";
export const dealRoomProgress = "dealRoomProgress";
export const dealRoomLink = "dealRoomLink";
export const dealRoomWriteMessage = "dealRoomWriteMessage";
export const dealRoomFinalStep = "dealRoomFinalStep";
const inboxTourSteps = [
  {
    selector: `[data-tour="${dealRoomWelcome}"]`,
    content: `Welcome to the deal room! This is your private space to ask questions to the sponsor team, review docs, and proceed on your investment.`,
  },
  {
    selector: `[data-tour="${dealRoomProgress}"]`,
    content: `Here's your investment progress. You can click on the button here to view all steps in the process.`,
  },
  {
    selector: `[data-tour="${dealRoomLink}"]`,
    content: `Click here to go back to the deal details.`,
  },
  {
    selector: `[data-tour="${dealRoomWriteMessage}"]`,
    content: `Feel free to write the sponsor with any of your questions here.`,
  },
  {
    selector: `[data-tour="${dealRoomFinalStep}"]`,
    content: `You can always return back to this guided tour by clicking this button.`,
  },
];

export default function ElmbaseTourProvider({
  children,
}: {
  children: ReactNode;
}) {
  const setGlobalState = useGlobalState((state) => state.setGlobalState);
  const pathname = usePathname();

  const params = {
    badgeContent: ({ totalSteps, currentStep }: any) =>
      currentStep + 1 + "/" + totalSteps,
    showCloseButton: false,
    styles: {
      badge: (base: any) => ({ ...base, backgroundColor: "#15803D" }),
      dot: (base: any) => ({
        ...base,
        backgroundColor: "#15803D",
        "--reactour-accent": "#15803D",
      }),
    },
    scrollSmooth: true,
    beforeClose: () => {
      setGlobalState({ hasJustClosedTour: true });
    },
  };

  if (pathname?.startsWith("/deal/")) {
    return (
      <TourProvider key="dealTour" {...params} steps={dealTourSteps}>
        {children}
      </TourProvider>
    );
  } else if (pathname?.startsWith("/inbox/")) {
    return (
      <TourProvider key="inboxTour" {...params} steps={inboxTourSteps}>
        {children}
      </TourProvider>
    );
  }
  return <>{children}</>;
}
