import axios from "axios";

export enum NavBarQueryKey {
  GET_USER_SIGNUP_DETAILS_FROM_ORG_CONTACT = "GET_USER_SIGNUP_DETAILS_FROM_ORG_CONTACT",
}

export const getUserSignUpDetailsFromOrgContact = async ({
  dealHandle,
  email,
}: {
  dealHandle: string;
  email: string;
}): Promise<{
  first_name: string;
  last_name: string;
  email: string;
  deal_handle: string;
}> => {
  const requestBody = {
    deal_handle: dealHandle,
    email,
  };
  return axios
    .post(`/api/get_user_details_from_org_contact`, requestBody)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Failed to get user contact details");
      }
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
