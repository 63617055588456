import {
  getConnection,
  properlyOrderUsers,
} from "www/shared/modules/connection_helpers";
import { clientSupabase } from "www/shared/modules/supabase";

const clearConnectionRequestAfterAccept = async ({
  user_id_1,
  user_id_2,
}: {
  user_id_1: string;
  user_id_2: string;
}) => {
  const { error } = await clientSupabase
    .from("connection_requests")
    .delete()
    .eq("from_user_id", user_id_1)
    .eq("to_user_id", user_id_2);

  if (error) {
    throw error;
  }
};

export const acceptConnectionRequest = async ({
  user_id_1,
  user_id_2,
}: {
  user_id_1: string;
  user_id_2: string | undefined;
}) => {
  if (!user_id_2) {
    throw Error("Something went wrong");
  }
  const [from_user_id, to_user_id] = properlyOrderUsers(user_id_1, user_id_2);

  const data = await getConnection({ user_id_1, user_id_2 });

  if (!data) {
    const { error } = await clientSupabase.from("connections").insert({
      from_user_id: from_user_id,
      to_user_id: to_user_id,
    });
    if (error) {
      throw error;
    }
  }
};

export const declineConnectionRequest = async ({
  user_id_1,
  user_id_2,
}: {
  user_id_1: string;
  user_id_2?: string;
}) => {
  if (!user_id_2) {
    throw Error("Something went wrong");
  }

  const data = await getConnection({ user_id_1, user_id_2 });

  if (!data) {
    clearConnectionRequestAfterAccept({
      user_id_1,
      user_id_2,
    });
  }
};
