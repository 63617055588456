// import partyemoji from "public/icons/partyemoji.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
import { useGlobalState } from "www/shared/modules/global_context";
// import Image from "next/image";

export default function BlogPostAlertBanner() {
  const supabaseUser = useGlobalState((s) => s.supabaseUser);
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const enabled = true; // disabled for now, since we don't have a new blog post yet

  useEffect(() => {
    setIsOpen(Boolean(!supabaseUser));
  }, [supabaseUser]);

  if (!enabled) return null;

  return (
    <div
      className={
        `flex p-3 text-white bg-green-900 hover:bg-green-800` +
        (isOpen ? "" : " hidden")
      }
    >
      <div className="flex flex-1 text-sm font-medium items-center justify-center ml-8">
        {/* <Image
          src={partyemoji}
          alt="partyemoji"
          width={20}
          height={20}
          className="w-5 h-5 max-w-5 max-h-5 min-w-5 min-h-5"
        /> */}
        <span className="text-lg mr-1">✨</span>{" "}
        <div className="sm:inline-flex hidden mr-1">Demystifying IRR — </div>{" "}
        <a
          target="_blank"
          href="https://blog.elmbase.com/exploring-return-metrics-of-real-estate-investment-opportunities-e8e0e3134120"
          rel="noreferrer"
        >
          <div className="inline-flex items-center font-semibold">
            Read Our Latest Blog Post{" "}
            <FontAwesomeIcon
              icon={faChevronRight}
              className="text-medium ml-3"
            />
          </div>
        </a>
      </div>
      <button
        type="button"
        className="w-8 hover:text-white text-gray-300"
        onClick={() => setIsOpen(false)}
      >
        <span className="sr-only">Close</span>
        <FontAwesomeIcon icon={faXmark} className="text-medium" />
      </button>
    </div>
  );
}
