import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-thin-svg-icons";
import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { BareModal } from "../modal/Modal";
import styles from "./LoginOrSignupModal.module.css";
import cx from "classnames";
import { useQueryClient } from "react-query";
import { signupWithEmailAndPassword } from "./LoginOrSignupModal.fetchers";
import { toast } from "react-toastify";
import { useGlobalState } from "www/shared/modules/global_context";
import classNames from "classnames";
import { usePathname, useRouter } from "next/navigation";
import { clientSupabase } from "www/shared/modules/supabase";
import { isAxiosError } from "axios";
import { invalidateReferralDealViews } from "www/pages/deal/DealReferral.fetchers";
import ElementWithTooltip from "../ElementWithTooltip";
import Image from "next/image";
import { indianDomain } from "www/shared/utils/handleDomains";
import { Button } from "../buttons/Button";

interface LoginOrSignupModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  variant?: "signup" | "login";
  userEmail?: string;
  firstName?: string;
  lastName?: string;
}
interface FormInputType {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  phone_number?: string;
}

interface ValidationError {
  message: string;
  errors: Record<string, string[]>;
}

export default function LoginOrSignupModal({
  open,
  setOpen,
  variant = "signup",
  userEmail,
  firstName,
  lastName,
}: LoginOrSignupModalProps) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showLoginForm, setShowLoginForm] = React.useState(false);

  const [modalVariant, setModalVariant] = React.useState<"signup" | "login">(
    "signup"
  );
  const path = usePathname();
  const router = useRouter();

  React.useEffect(() => {
    setModalVariant(variant);
  }, [variant]);
  useEffect(() => {
    setShowLoginForm(false);
  }, [modalVariant]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<FormInputType>({
    reValidateMode: "onChange",
    defaultValues: {
      email: variant === "signup" ? userEmail || "" : "",
      first_name: variant === "signup" ? firstName || "" : "",
      last_name: variant === "signup" ? lastName || "" : "",
    },
  });

  useEffect(() => {
    if (variant === "signup") {
      reset({
        email: userEmail || "",
        first_name: firstName || "",
        last_name: lastName || "",
      });
    }
  }, [userEmail, firstName, lastName, reset]);

  const setGlobalState = useGlobalState((s) => s.setGlobalState);
  const queryClient = useQueryClient();

  const loginEmailPassword = async () => {
    let { email, password } = getValues();
    const res = await clientSupabase.auth.signInWithPassword({
      email,
      password,
    });
    if (res.error !== null) {
      let errorMessage = res.error.message;
      toast.error(errorMessage);
      return;
    }
    const {
      data: { session },
    } = await clientSupabase.auth.getSession();
    console.log("session from logging in: ", session);
    setGlobalState({
      supabaseUser: session?.user,
      isUpdateModalOpen: modalVariant === "signup" ? true : false,
      isAuthModalOpen: false,
    });
    console.log("invalidating deal referral view");
    // invalidate deal referral view to refresh the page
    invalidateReferralDealViews(queryClient);
    console.log("done invalidating deal referral view");
  };

  const signupEmailPassword = async () => {
    let { email, password, first_name, last_name, phone_number } = getValues();
    try {
      const response = await signupWithEmailAndPassword({
        first_name,
        last_name,
        email,
        password,
        phone_number,
      });
      if (response?.data) {
        if (response.data.status === "UserAlreadyRegistered") {
          toast.success(
            "That email is already taken, if it is you please login"
          );
        } else {
          loginEmailPassword();
        }
      }
    } catch (error) {
      if (isAxiosError<ValidationError, Record<string, unknown>>(error)) {
        toast.error(error.response?.data.message);
      } else {
        console.error(error);
      }
      throw error;
    }
  };

  async function signInWithGoogle() {
    const { error } = await clientSupabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: window.location.origin + path,
      },
    });
    if (error) {
      toast.error("Error logging in with Google, please try again.");
      console.log(error.message);
    }
  }
  const currentDomain = typeof window !== "undefined" && window.location.host;

  return (
    <>
      <BareModal
        isBgTransparent={true}
        widthClasses="w-full sm:max-w-[480px]"
        isOpen={open}
        setIsOpen={setOpen}
      >
        <p className="text-white text-center text-base font-semibold mb-3">
          {modalVariant === "signup"
            ? "Sign up to continue"
            : "Log in to continue"}
        </p>
        <div className="bg-white px-3 sm:px-[65px] pt-14 rounded-t-lg pb-4">
          <button
            className="w-5 h-5 absolute top-10 right-4"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FontAwesomeIcon
              className="w-[18px] h-[28px] text-green-700 hover:text-green-800"
              icon={faXmark}
            />
          </button>
          <div className="flex justify-center gap-3 items-center">
            {currentDomain == indianDomain ? (
              <div>
                <Image width={200} height={50} src="/logoIn.svg" alt="Logo" />
              </div>
            ) : (
              <>
                <Image
                  className="h-8 min-h-9 max-h-8 w-[31px] min-w-[31px] max-w-[31px]"
                  height={32}
                  width={31}
                  src="/logo_v2.svg"
                  alt="Logo"
                />
                <p className="text-green-700 text-3xl font-medium">ELMBASE</p>
              </>
            )}
          </div>
          <div className="w-full mt-8 overflow-y-auto">
            <div className="flex flex-col gap-6 mx-[1px]">
              {modalVariant === "signup" && (
                <p className="text-sm md:text-base font-semibold text-gray-500 text-center">
                  Sign up to discover exclusive real estate opportunities and
                  connect with others.
                </p>
              )}
              <div className="flex gap-4 flex-col">
                <button
                  className="w-full flex items-center text-center gap-2 justify-center py-3 bg-white rounded-md border border-[#D1DBE3]"
                  onClick={signInWithGoogle}
                >
                  <Image
                    className="cursor-pointe w-6 h-6 max-w-6 max-h-6 min-w-6 min-h-6"
                    height={24}
                    width={24}
                    src="/google.svg"
                    alt="Continue with Google"
                  />{" "}
                  <span className="text-sm font-medium text-gray-600">
                    Continue with Google
                  </span>
                </button>
                <ElementWithTooltip
                  sourceElement={
                    <button
                      className="w-full flex items-center text-center gap-2 justify-center py-3 bg-white rounded-md border border-[#D1DBE3] opacity-50"
                      disabled={true}
                    >
                      <Image
                        className="cursor-pointe w-6 h-6 max-w-6 max-h-6 min-w-6 min-h-6"
                        height={24}
                        width={24}
                        src="/linkedin.svg"
                        alt="Continue with Linkedin"
                      />{" "}
                      <span className="text-sm leading-5 font-medium text-gray-600">
                        Continue with Linkedin
                      </span>
                    </button>
                  }
                  uniqueId={"linkedin"}
                >
                  Coming soon
                </ElementWithTooltip>
              </div>
              <div className="flex items-center gap-[10px]">
                <div className="w-full h-[1px] bg-gray-200"></div>
                <p className="text-gray-500 text-base">OR</p>
                <div className="w-full h-[1px] bg-gray-200"></div>
              </div>
              {!showLoginForm && (
                <button
                  className="font-medium text-base leading-[39px] text-green-700 hover:text-green-800 mx-auto w-fit"
                  onClick={() => {
                    setShowLoginForm(!showLoginForm);
                  }}
                >
                  {modalVariant === "signup"
                    ? "Sign Up with Email Address"
                    : "Sign In with Email Address"}
                </button>
              )}
              {showLoginForm && (
                <div>
                  <div className={cx("flex flex-col gap-3", styles.loginForm)}>
                    {/* First name and last name section */}
                    {modalVariant === "signup" && (
                      <div className=" grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label htmlFor="first_name" className="sr-only">
                            First name
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            className={classNames(
                              "block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm px-3 py-[14px] placeholder:text-gray-400 text-sm leading-5 font-normal",
                              errors.first_name ? "border-red-500" : ""
                            )}
                            autoComplete="family-name"
                            placeholder="First name"
                            {...register("first_name", {
                              required: "First name is required",
                            })}
                          />
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="last_name" className="sr-only">
                            Last name
                          </label>
                          <input
                            id="last_name"
                            type="text"
                            className={classNames(
                              "block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm px-3 py-[14px] placeholder:text-gray-400 text-sm leading-5 font-normal",
                              errors.last_name ? "border-red-500" : ""
                            )}
                            autoComplete="given-name"
                            placeholder="Last name"
                            {...register("last_name", {
                              required: "Last name is required",
                            })}
                          />
                        </div>
                      </div>
                    )}
                    <div>
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className={classNames(
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm px-3 py-[14px] placeholder:text-gray-400 text-sm leading-5 font-normal",
                          errors.email ? "border-red-500" : ""
                        )}
                        placeholder="Email"
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                    </div>
                    {modalVariant === "signup" && (
                      <div>
                        <label htmlFor="phone_number" className="sr-only">
                          Phone number
                        </label>
                        <input
                          type="text"
                          id="phone_number"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm px-3 py-[14px] placeholder:text-gray-400 text-sm leading-5 font-normal"
                          placeholder="Phone number (optional)"
                          {...register("phone_number")}
                        />
                      </div>
                    )}
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className={classNames(
                          "block w-full rounded-md border-gray-300 pr-10 focus:border-green-500 focus:ring-green-500 sm:text-sm pl-3 py-[14px] placeholder:text-gray-400 text-sm leading-5 font-normal",
                          errors.password ? "border-red-500" : ""
                        )}
                        placeholder="Password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                      <button
                        className=" absolute inset-y-0 right-0 flex items-center pr-3"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className="h-[12.04px] w-[13.62px] text-gray-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="h-[12.04px] w-[13.62px] text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                    {modalVariant === "login" && (
                      <a
                        href={"/?login=true&forgotPassword=true"}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm leading-5 font-medium text-green-700 text-right"
                      >
                        {" "}
                        Forgot password?
                      </a>
                    )}
                    <Button
                      className="py-3 mt-4"
                      onClick={handleSubmit(() => {
                        modalVariant === "signup"
                          ? signupEmailPassword()
                          : loginEmailPassword();
                      })}
                    >
                      {modalVariant === "signup" ? "Sign Up" : "Log In"}
                    </Button>
                  </div>
                  {modalVariant === "signup" && (
                    <p className="text-xs leading-5 font-medium text-left mt-3 text-gray-500">
                      By signing up you agree to our{" "}
                      <span className="text-green-700 hover:text-green-800 font-semibold cursor-pointer">
                        <a
                          onClick={() => {
                            setOpen(false);
                            router.push("/terms_of_service");
                          }}
                        >
                          Terms,{"  "}
                        </a>
                        <a
                          onClick={() => {
                            setOpen(false);
                            router.push("/privacy_policy");
                          }}
                        >
                          {" "}
                          Privacy Policy
                        </a>
                      </span>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white sticky bottom-0 left-0 right-0 shadow-2xl py-6 rounded-b-lg">
          {modalVariant === "signup" ? (
            <p className="text-sm font-medium text-center text-gray-600">
              Have an account?{" "}
              <span
                className="text-green-700 hover:text-green-800 font-semibold cursor-pointer"
                onClick={() => {
                  setModalVariant("login");
                }}
              >
                Log In
              </span>
            </p>
          ) : (
            <p className="text-sm font-medium text-center text-gray-600">
              Don&apos;t have an account?{" "}
              <span
                className="text-green-700 hover:text-green-800 font-semibold cursor-pointer"
                onClick={() => {
                  setModalVariant("signup");
                }}
              >
                Sign Up
              </span>
            </p>
          )}
        </div>
      </BareModal>
    </>
  );
}
