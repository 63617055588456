import { QueryClient } from "react-query";
import { Notification } from "types/tables";
import { NotificationsView } from "types/views";
import { clientSupabase } from "www/shared/modules/supabase";

export enum NotificationQueryKey {
  NotificationView = "NotificationView",
  UnreadInboxConversations = "UnreadInboxConversations",
}

export const fetchUnreadConversationsCount = async (userId: string | null) => {
  const { data, error } = await clientSupabase
    .from("inbox_unread_messages_for_cur_user_view")
    .select()
    .filter("user_id", "eq", userId)
    .or("has_read_latest_message.eq.false, is_marked_unread.eq.true");

  if (error) throw error;

  return data.length;
};

export const fetchNotifications = async (userId: string | undefined | null) => {
  if (!userId) {
    return null;
  }

  const response = await clientSupabase
    .from("notifications_for_cur_user_view")
    .select()
    .eq("user_id", userId)
    // .eq("user_id", "f90464fb-8fee-47c2-b641-09ec35e59827")
    .limit(1)
    .single<NotificationsView>();

  if (response.error) throw response.error;

  return response;
};
interface NotificationUpdate {
  id: number;
  receiving_user_id: string;
  from_user_id: string;
  notification_type: Notification["Row"]["notification_type"];
  is_seen: boolean;
}
export const markNotificationsAsSeen = async (
  notifications: NotificationUpdate[]
) => {
  if (!notifications.length) {
    return;
  }
  const response = await clientSupabase
    .from("notifications")
    .upsert([...notifications]);

  if (response.error) throw response.error;

  return response;
};

export const markConnectionRequestAsSeen = async (
  connectionRequestIds: number[]
) => {
  if (!connectionRequestIds.length) {
    return;
  }
  // for each connection request, mark it as seen
  for (const id of connectionRequestIds) {
    const { error } = await clientSupabase
      .from("connection_requests")
      .update({ is_seen: true })
      .eq("id", id);
    if (error) {
      throw error;
    }
  }
};

export const subscribeToNewNotifications = function (
  user_id: string | undefined,
  queryClient: any
) {
  clientSupabase
    .channel(`public:notifications:receiving_user_id=eq.${user_id}`)
    .on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "notifications",
        filter: `receiving_user_id=eq.${user_id}`,
      },
      () => invalidateNotificationViews(queryClient)
    )
    .subscribe();
};
export const invalidateNotificationViews = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(NotificationQueryKey.NotificationView);
};

export const invalidateUnreadMessagesViews = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(NotificationQueryKey.UnreadInboxConversations);
};
