import { QueryClient } from "react-query";
import {
  DealPageView,
  ProfilePageView,
  SponsorDeal,
  SponsorDealsView,
} from "types/views";
import { clientSupabase } from "www/shared/modules/supabase";
import { CountryEnum } from "www/shared/utils/CountryEnum";
import { v4 as uuidv4 } from "uuid";
import { getEncodedLekkoConfigs } from "@lekko/next-sdk";
import { PortfolioDealStatus, PortfolioDealTransactionType, PortfolioForCurUserView } from "app/dashboard/Dashboard.types";
import axios from "axios";
// import { GoogleAuth } from "google-auth-library";
// const supabaseHostUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
const hostUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;

export interface PortfolioTransactionToInsert {
  portfolio_deal_id: number;
  transaction_type: PortfolioDealTransactionType;
  amount: number | null;
  actual_date: string | null;
}

export enum AppQueryKey {
  UserProfile = "UserProfile",
  UserPreferences = "UserPreferences",
  SponsorDeals = "SponsorDeals",
  GoogleAccessToken = "GoogleAccessToken",
  UserCheck = "UserCheck",
  UserProfileOverrides = "UserProfileOverrides",
  FetchLekkoConfigs = "FetchLekkoConfigs",
  FetchAllDealCommitments = "FetchAllDealCommitments",
  UserPortfolio = "UserPortfolio",
  IngestPortfolioInvestmentsForUser = "IngestPortfolioInvestmentsForUser",
}

export const fetchUserPortfolio = async ({ userId }: { userId: string }) => {
  const { data: portfolio, error: portfolioDataError } = await clientSupabase
    .from("portfolio_for_cur_user_view")
    .select()
    .eq("owner_user_id", userId)
    .single<PortfolioForCurUserView>();
  if (portfolioDataError) {
    throw portfolioDataError;
  }
  return portfolio;
};

export async function setUserProfileFromGoogleAuth(
  access_token: string,
  userProfile: ProfilePageView
) {
  console.log("Access token: ", access_token);
  // const auth = new GoogleAuth({
  //   scopes: "https://www.googleapis.com/auth/cloud-platform",
  // });
  // auth.set;
  const {
    data: { session },
  } = await clientSupabase.auth.getSession();
  const user = await clientSupabase.auth.getUser(session?.access_token);

  const userProfileToUpdate: {
    first_name?: string;
    last_name?: string;
    profile_pic_url?: string;
    handle?: string;
    subtitle: string;
  } = {
    subtitle: "Investor",
  };

  if (
    !userProfile.profile_pic_url &&
    user.data.user?.user_metadata.avatar_url
  ) {
    // this isn't working for some reason, getting cors errors
    // const image = await fetch(user.data.user?.user_metadata.avatar_url, {
    //   mode: "no-cors",
    // });
    // const blob = await image.blob();
    // const file = new File([blob], "profile_pic", { type: "image/png" });
    // const { data, error: imageError } = await clientSupabase.storage
    //   .from("public")
    //   .upload(`users/${user.data.user?.id}/profile_pic.png`, file, {
    //     cacheControl: "1",
    //     upsert: true,
    //   });
    // if (imageError) {
    //   console.log(imageError);
    // }
    // userProfileToUpdate.profile_pic_url = `${supabaseHostUrl}/storage/v1/object/public/public/${data?.path}`;

    try {
      const profileImagePath = `users/${
        user.data.user.id
      }/profile_pic_${uuidv4()}.png`;

      const profileImageUrl = await fetch(
        `${user.data.user?.user_metadata.avatar_url.split("=s")[0]}=s1000-c`
      );

      const profileImageBlob = await profileImageUrl.blob();

      const profilePicUpload = await clientSupabase.storage
        .from("public")
        .upload(`${profileImagePath}`, profileImageBlob, {
          cacheControl: "1",
          upsert: true,
        });

      if (profilePicUpload?.data) {
        userProfileToUpdate.profile_pic_url = `${hostUrl}/storage/v1/object/public/public/${profileImagePath}`;
      } else {
        return;
      }
    } catch (error) {
      userProfileToUpdate.profile_pic_url =
        user.data.user?.user_metadata.avatar_url.split("=s")[0] + "=s1000-c";
      console.log(error);
    }
  }

  if (!userProfile.first_name) {
    userProfileToUpdate.first_name =
      user.data.user?.user_metadata.full_name.split(" ")[0];
    userProfileToUpdate.last_name =
      user.data.user?.user_metadata.full_name.split(" ")[1];
  }

  const { error } = await clientSupabase
    .from("user_profiles")
    .update(userProfileToUpdate)
    .eq("user_id", userProfile.user_id!);

  if (error) {
    throw error;
  }

  // now set the handle if it's not set
  if (!userProfile.handle && user.data.user?.user_metadata.full_name) {
    // replace spaces and special characters with underscores from user.data.user.user_metadata.full_name;
    const handle = user.data.user?.user_metadata.full_name
      .replace(/[^a-zA-Z0-9]/g, "_")
      .toLowerCase();

    const { error } = await clientSupabase
      .from("user_profiles")
      .update({ handle: handle })
      .eq("user_id", userProfile.user_id!);

    if (error) {
      const DUPLICATE_ENTRY = "23505";
      if (error.code === DUPLICATE_ENTRY) {
        // that handle already exists so we just set it to the user's id
        const { error } = await clientSupabase
          .from("user_profiles")
          .update({ handle: userProfile.user_id })
          .eq("user_id", userProfile.user_id!)
          .limit(1);
        if (error) {
          throw error;
        }
      } else {
        throw error;
      }
    }
  }
}

export async function fetchUserProfile(userId: string) {
  const { data, error } = await clientSupabase
    .from("profile_page_for_cur_user_view")
    .select()
    .eq("user_id", userId)
    .limit(1)
    .returns<ProfilePageView[]>();

  if (error) {
    throw error;
  }
  if (data && data.length > 0) {
    return data[0];
  }
  return null;
}
export async function fetchUserPreferences(userId: string) {
  const { data, error } = await clientSupabase
    .from("user_preferences")
    .select()
    .eq("user_id", userId)
    .limit(1);
  if (error) {
    throw error;
  }
  if (data && data.length > 0) {
    return data[0];
  }

  //  else {
  // await clientSupabase.from("user_preferences").insert({ user_id: userId });
  // }
  return null;
}

export async function fetchUserProfileOverrides(userId: string) {
  const { data, error } = await clientSupabase
    .from("user_profile_overrides")
    .select()
    .eq("user_id", userId)
    .limit(1);
  if (error) throw error;
  if (data && data.length > 0) {
    return data[0];
  }
  return null;
}

export async function fetchSponsorDeals(
  userId: string
): Promise<SponsorDeal[] | null> {
  const { data, error } = await clientSupabase
    .from("sponsor_deals_for_cur_user_view")
    .select("*")
    .eq("user_id", userId)
    .single<SponsorDealsView>();
  if (error) {
    throw error;
  }
  return data!.deals;
}

type DealCommitment = Pick<
  DealPageView,
  | "id"
  | "title"
  | "location"
  | "funds_due_date"
  | "deal_sponsors"
  | "unique_share_link"
  | "firm_committed_amount"
  | "funds_wired_marked_completed_at"
  | "has_completed_investment"
  | "projected_return_aar"
  | "projected_term"
>;

export async function addDealsToPortfolio({
  deals: dealCommitments,
  portfolioId,
}: {
  deals: DealCommitment[];
  portfolioId: number;
}) {
  const { data: existingDeals, error: existingDealsError } =
    await clientSupabase
      .from("portfolio_deals")
      .select("elmbase_deal_id")
      .in("elmbase_deal_id", dealCommitments.map((deal) => deal.id!))
      .eq("portfolio_id", portfolioId);
  if (existingDealsError) {
    throw existingDealsError;
  }
  const existingElmbaseDealIds = existingDeals?.map((deal) => deal.elmbase_deal_id!);

  const toInsert = dealCommitments
    .filter((dealCommitment) =>
      existingElmbaseDealIds.every(
        (existingDealId) => existingDealId !== dealCommitment.id
      )
    )
    .map((dealCommitment) => {
      let sponsor = null;
      if (
        dealCommitment.deal_sponsors &&
        dealCommitment.deal_sponsors.length > 0
      ) {
        sponsor = dealCommitment.deal_sponsors[0];
      }

      return {
        elmbase_deal_id: dealCommitment.id,
        portfolio_id: portfolioId,
        investment_name: dealCommitment.title,
        location: dealCommitment.location,
        sponsor_name: sponsor?.current_org_name,
        sponsor_website:
          sponsor?.current_org_handle ??
          `https://elmbase.com/organization/${sponsor?.current_org_handle}`,
        sponsor_contact_name:
          sponsor?.first_name && sponsor?.last_name
            ? `${sponsor.first_name} ${sponsor.last_name}`
            : undefined,
        status: "Considering" as PortfolioDealStatus,
        projected_return_aar: dealCommitment.projected_return_aar,
        projected_term: dealCommitment.projected_term,
      };
    });

  if (toInsert.length === 0) {
    return;
  }

  const { data, error } = await clientSupabase
    .from("portfolio_deals")
    .insert(toInsert)
    .select("id, elmbase_deal_id");

  if (error) {
    throw error;
  }

  const initialTransactionsToInsert = data
    ?.map((deal) => {
      const dealCommitment = dealCommitments.find(
        (dealCommitment) =>
          dealCommitment.id === deal.elmbase_deal_id &&
          dealCommitment.has_completed_investment && dealCommitment.firm_committed_amount !== null
      );
      if (!dealCommitment) {
        return null;
      }
      return {
        portfolio_deal_id: deal.id,
        transaction_type: "InitialContribution" as PortfolioDealTransactionType,
        amount: dealCommitment.firm_committed_amount,
        actual_date: dealCommitment.funds_due_date,
      };
    })
    .filter(
      (transaction) => transaction !== null
    ) as PortfolioTransactionToInsert[];
  await addTransactionsToPortfolioDeals({
    transactionData: initialTransactionsToInsert,
  });

  return data;
}

export const addTransactionsToPortfolioDeals = async ({
  transactionData,
}: {
  transactionData: PortfolioTransactionToInsert[];
}) => {
  const { error } = await clientSupabase
    .from("portfolio_deal_transactions")
    .insert(transactionData);
  if (error) {
    throw error;
  }
  const { error: markInvestedDealsStatusError } = await clientSupabase
    .from("portfolio_deals")
    .update({ status: "Invested" })
    .in(
      "id",
      transactionData.map((transaction) => transaction.portfolio_deal_id)
    );
  if (markInvestedDealsStatusError) {
    throw markInvestedDealsStatusError;
  }
};

export async function fetchDealCommitments() {
  const { data, error } = await clientSupabase
    .from("deal_page_for_cur_user_view")
    .select(
      `id, 
      title,
      location,
      funds_due_date,
      deal_sponsors,
      unique_share_link,
      firm_committed_amount, 
      funds_wired_marked_completed_at, 
      has_completed_investment,
      projected_return_aar,
      projected_term`
    )
    .not("unique_share_link", "is", null)
    .returns<DealCommitment[]>();
  if (error) {
    throw error;
  }

  return data;
}

export async function doesUserHaveAccessToADeal() {
  const { data, error } = await clientSupabase
    .from("deal_page_for_cur_user_view")
    .select("id");
  if (error) {
    throw error;
  }
  return data?.length > 0;
}
export async function setCountryDomainsToProfile(
  userId: string,
  countryDomains: CountryEnum[]
): Promise<void> {
  const { error } = await clientSupabase
    .from("user_profiles")
    .update({
      country_domains: countryDomains,
    })
    .eq("user_id", userId);
  if (error) {
    throw error;
  }
}

export const ingestPortfolioInvestmentsForUser = async ({
  email,
  portfolioId,
}: {
  email: string;
  portfolioId: number;
}) => {
  const reqBody = {
    email,
    portfolioId,
  };
  await axios.post("/api/ingest_portfolio_investments", reqBody);
}

export async function getLekkoConfigs() {
  return await getEncodedLekkoConfigs();
}

export const invalidateAppQuery = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(AppQueryKey.UserProfile);
};

export const invalidateUserPreference = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(AppQueryKey.UserPreferences);
};
