import React, { useEffect, useState } from "react";
import Script from "next/script";
import { useGlobalState } from "www/shared/modules/global_context";

export default function HeapAnalytics() {
  const { userProfile, supabaseUser } = useGlobalState();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  // Use a custom heap ID if you want to send analytics to a different env / project, otherwise it'll send to the
  // "development" env in Heap
  const heapId = process.env.NEXT_PUBLIC_CUSTOM_HEAP_ID ?? 4113878654;

  useEffect(() => {
    if (!!userProfile && (window as any).heap) {
      (window as any).heap.identify(supabaseUser?.email);
      (window as any).heap.addUserProperties({
        name: userProfile.first_name + " " + userProfile.last_name,
        email: supabaseUser?.email,
        userId: userProfile.user_id,
      });
    }
  }, [scriptLoaded, userProfile, supabaseUser]);

  const scriptReady = () => {
    if ((window as any).heap) {
      setScriptLoaded(true);
    }
  };

  return (
    <Script
      id="heap-analytics"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("${heapId}");
      `,
      }}
      onReady={scriptReady}
    />
  );
}
