import Link from "next/link";
import React from "react";
import { Notification } from "types/views";
import { getFullName } from "www/shared/utils";
import NotificationCardContainer from "../NotificationCardContainer";

export type RequestNotification = {
  profilePicsUrl: string;
  name: string;
  time: string;
  type: Notification["notification_type"];
  isSeen: boolean;
  handle: string;
  id: number;
};

export default function RequestNotificationCard({
  notification,
  isInMenu,
}: {
  notification: Notification;
  isInMenu?: boolean;
}) {
  let text = "";
  if (notification.notification_type === "HasAcceptedYourConnectionRequest") {
    if (!notification.first_name) {
      text = "Someone just accepted your deal invite!";
    }
    text = "Is now connected with you.";
  } else if (notification.notification_type === "SentYouAConnectionRequest") {
    text = "Sent you a connection request.";
  }

  return (
    <Link href={`/p/${notification.handle}`} legacyBehavior>
      <div className="cursor-pointer">
        <NotificationCardContainer
          id={notification.id}
          profilePicUrl={notification.profile_pic_url}
          name={getFullName({
            firstName: notification.first_name ?? "",
            lastName: notification.last_name,
          })}
          time={notification.created_at}
          isSeen={notification.is_seen}
          handle={`/p/${notification.handle}`}
          isInMenu={isInMenu}
        >
          <div className="text-sm text-gray-500">
            <p className="inline">{text}</p>{" "}
          </div>
        </NotificationCardContainer>
      </div>
    </Link>
  );
}
