import { PostgrestError } from "@supabase/supabase-js";
import axios, { AxiosResponse } from "axios";
import { DealInvitesApiResponse } from "api/modules/deal_invite_helpers";
import { QueryClient } from "react-query";
import { DealShareInviteApiRequestBody } from "pages/api/deal_referral";
export enum ReferralDealQueryKey {
  ReferralDealView = "ReferralDealView",
}
type DealInviteFetchResponse = DealInvitesApiResponse & {
  error: PostgrestError | null;
};

export const fetchDealInviteByDealAccess: (
  handle: string,
  access: string,
  toEmail: string | undefined,
  params?: Record<string, string>
) => Promise<DealInviteFetchResponse> = async (
  handle: string,
  access: string,
  toEmail: string | undefined,
  params?: Record<string, string>
) => {
  const reqBody: DealShareInviteApiRequestBody = {
    dealHandle: handle,
    dealAccess: access,
    toEmail: toEmail,
    params: params,
  };
  return axios
    .post(`/api/deal_referral`, reqBody)
    .then(({ data }: AxiosResponse<DealInviteFetchResponse>) => data)
    .catch((err) => {
      throw err;
    });
};

export const invalidateReferralDealViews = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(ReferralDealQueryKey.ReferralDealView);
};
