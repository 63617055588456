import { Tab } from "@headlessui/react";
import { useGlobalState } from "www/shared/modules/global_context";
import React, { useCallback } from "react";
import { classNames } from "www/shared/utils";
import { useMutation, useQueryClient } from "react-query";

import {
  invalidateNotificationViews,
  markConnectionRequestAsSeen,
  markNotificationsAsSeen,
  subscribeToNewNotifications,
} from "./NotificationMenu.fetchers";
import NotificationCardSelector from "./NotificationCardSelector";
import { NotificationsView } from "types/views";
import ConnectionRequestCards from "./ConnectionRequestCards";
import _ from "lodash";

interface NotificationMenuProps {
  notificationsViewData: NotificationsView | null | undefined;
  onNotificationClick?: () => void;
}

export default function NotificationMenu({
  notificationsViewData,
  onNotificationClick,
}: NotificationMenuProps) {
  const supabaseUser = useGlobalState((s) => s.supabaseUser);
  const queryClient = useQueryClient();

  const markUnSeenNotificationsAsSeen = useMutation(markNotificationsAsSeen, {
    onSuccess: () => {
      invalidateNotificationViews(queryClient);
    },
    onError: (err) => {
      console.log("error", err);
    },
  });

  const markUnSeenConnectionRequestsAsSeen = useMutation(
    markConnectionRequestAsSeen,
    {
      onSuccess: () => {
        invalidateNotificationViews(queryClient);
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );

  subscribeToNewNotifications(supabaseUser?.id, queryClient);

  const simpleNotifications = notificationsViewData?.notifications;
  const simpleUnseenNotifications =
    notificationsViewData?.notifications?.filter((n) => n.is_seen === false);

  const requestNotifications = notificationsViewData?.connection_requests;
  const requestUnseenNotifications =
    notificationsViewData?.connection_requests?.filter(
      (n) => n.is_seen === false
    );

  // for some reason this component rerenders a bunch of extra times, so we need to useCallback
  const viewNotification = useCallback(() => {
    if (
      (simpleUnseenNotifications?.length || 0) > 0 &&
      markUnSeenNotificationsAsSeen.isIdle
    ) {
      setTimeout(async () => {
        markUnSeenNotificationsAsSeen.mutate(
          simpleUnseenNotifications?.map((n) => ({
            id: n.id,
            is_seen: true,
            receiving_user_id: n.receiving_user_id,
            from_user_id: n.from_user_id,
            notification_type: n.notification_type,
          })) || []
        );
      }, 200);
    }
  }, [markUnSeenNotificationsAsSeen, simpleUnseenNotifications]);

  const viewConnectionRequests = useCallback(() => {
    if (
      (requestUnseenNotifications?.length || 0) > 0 &&
      markUnSeenConnectionRequestsAsSeen.isIdle
    ) {
      setTimeout(async () => {
        markUnSeenConnectionRequestsAsSeen.mutate(
          requestUnseenNotifications?.map((n) => n.id) || []
        );
      }, 200);
    }
  }, [markUnSeenConnectionRequestsAsSeen, requestUnseenNotifications]);

  return (
    <Tab.Group defaultIndex={1}>
      <Tab.List className="border-b border-gray-200 flex gap-x-5 px-5">
        <Tab>
          {({ selected }) => {
            if (selected) {
              viewConnectionRequests();
            }
            return (
              <div
                className={classNames(
                  selected
                    ? "after:bg-green-700 after:text-green-700"
                    : "after:bg-transparent text-gray-500 hover:text-gray-600 hover:after:bg-gray-200",
                  "flex items-center gap-x-1 py-[12px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:w-full after:h-0.5 after:rounded-tl after:rounded-tr"
                )}
              >
                <span className="font-medium text-sm cursor-pointer focus:outline-none focus:ring focus:ring-white">
                  Connection Requests
                </span>
                {requestUnseenNotifications &&
                  requestUnseenNotifications?.length > 0 && (
                    <span className="w-[18px] h-[18px] pr-[1px] flex justify-center items-center bg-green-700 font-medium text-white rounded-[4px] text-[10px]">
                      {requestUnseenNotifications?.length}
                    </span>
                  )}
              </div>
            );
          }}
        </Tab>
        <Tab>
          {({ selected }) => {
            if (selected) {
              viewNotification();
            }
            return (
              <div
                className={classNames(
                  selected
                    ? "after:bg-green-700 after:text-green-700"
                    : "after:bg-transparent text-gray-500 hover:text-gray-600 hover:after:bg-gray-200",
                  "flex items-center gap-x-1 py-[12px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:w-full after:h-0.5 after:rounded-tl after:rounded-tr"
                )}
              >
                <span className="font-medium text-sm cursor-pointer focus:outline-none focus:ring focus:ring-white">
                  Notifications
                </span>
                {requestUnseenNotifications &&
                  requestUnseenNotifications?.length > 0 && (
                    <span className="w-[18px] h-[18px] pr-[1px] flex justify-center items-center bg-green-700 font-medium text-white rounded-[4px] text-[10px]">
                      {simpleUnseenNotifications?.length}
                    </span>
                  )}
              </div>
            );
          }}
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          {requestNotifications && requestNotifications.length > 0 ? (
            <>
              <div onClick={onNotificationClick}>
                <ConnectionRequestCards
                  requestNotifications={_.orderBy(
                    requestNotifications,
                    "created_at",
                    "desc"
                  )}
                />
              </div>
              {requestNotifications.length > 10 && <SeeAllNotifications />}
            </>
          ) : (
            <EmptyNotification type="requestNotifications" />
          )}
        </Tab.Panel>
        <Tab.Panel>
          {simpleNotifications && simpleNotifications.length > 0 ? (
            <>
              <div onClick={onNotificationClick}>
                <NotificationCardSelector notifications={simpleNotifications} />
              </div>
              {simpleNotifications.length > 10 && <SeeAllNotifications />}
            </>
          ) : (
            <EmptyNotification />
          )}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}

const EmptyNotification = ({
  type = "simpleNotifications",
}: {
  type?: "requestNotifications" | "simpleNotifications";
}) => {
  return (
    <div className=" h-full px-5 py-4">
      {type === "requestNotifications" ? (
        <p className="text-sm leading-5 font-normal text-gray-500">
          You don&apos;t have any connection requests
        </p>
      ) : (
        <p className="text-sm leading-5 font-normal text-gray-500">
          You don&apos;t have any notifications.
        </p>
      )}
    </div>
  );
};
const SeeAllNotifications = () => {
  return (
    <div className="  hover:bg-gray-50 cursor-pointer">
      <p className="text-sm font-medium text-gray-900 border-t-gray-200 border py-4 px-5">
        See All Notifications
      </p>
    </div>
  );
};
