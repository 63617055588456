import React from "react";
import { classNames } from "www/shared/utils";

const Container = ({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      className={classNames(
        "w-full max-w-[1252px] sm:max-w-[1266px] lg:max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8",
        className
      )}
      {...props}
    />
  );
};

export default Container;
