import Link from "next/link";
import React from "react";
import { Notification } from "types/views";
import { getFullName } from "www/shared/utils";
import NotificationCardContainer from "../NotificationCardContainer";
import { getNotificationText } from "./notificationText";

export default function DealNotificationCard({
  notification,
  isInMenu,
}: {
  notification: Notification;
  isInMenu?: boolean;
}) {
  const { preText } = getNotificationText(
    notification.notification_type,
    notification.attached_deal_comment_type
  );
  
  const dealLink = notification.attached_deal_unique_share_link
    ? `/deal/${notification.attached_deal_handle}/${notification.attached_deal_unique_share_link}`
    : `/deal/${notification.attached_deal_handle}`;
  const dealRoomLink = notification.is_receiving_user_sponsor && notification.deal_room_id ? `/inbox/${notification.deal_room_id}` : null;
  const link = notification.notification_type === "ExpressedInterestInYourDeal" && dealRoomLink ? dealRoomLink : dealLink;

  return (
    <Link href={link} className="" legacyBehavior>
      <div className="cursor-pointer">
        <NotificationCardContainer
          id={notification.id}
          profilePicUrl={notification.profile_pic_url}
          name={getFullName({
            firstName: notification.first_name ?? "",
            lastName: notification.last_name,
          })}
          time={notification.created_at}
          isSeen={notification.is_seen}
          handle={`/p/${notification.handle}`}
          isInMenu={isInMenu}
        >

          <div className="text-sm font-normal text-gray-500">
            <p className="inline">{preText}</p>{" "}
            <p className="inline font-semibold ">
              {notification.attached_deal_title}
            </p>
          </div>
        </NotificationCardContainer>
      </div>
    </Link>
  );
}