import { Fragment, useCallback, useEffect, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import { modalZIndex } from "www/shared/utils/constants";

interface BaseModalProps {
  isBgTransparent?: boolean;
  widthClasses?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string | null;
  children?: ReactNode;
  showButtonInMiddle?: boolean;
  isShowingModalFooter?: boolean;
}

interface InfoModalProps extends BaseModalProps {
  onSave?: never;
  isSaving?: never;
  showContinueButton: boolean; // if false, then shows it on the right side
}

interface SaveModalProps extends BaseModalProps {
  onSave: () => void;
  isSaving: boolean;
  showContinueButton?: never;
}

// Use ModalWrapper for either Info modals or Save modals.
export default function Modal({
  isBgTransparent,
  widthClasses,
  isOpen,
  setIsOpen,
  title,
  children,
  onSave,
  isSaving,
  showContinueButton,
  showButtonInMiddle,
  isShowingModalFooter=true,
}: InfoModalProps | SaveModalProps) {
  const keyPressedFn = useCallback(
    ({ keyCode }: { keyCode: number; [id: string]: any }) => {
      if (keyCode === 27) {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPressedFn, false);
    return () => {
      document.removeEventListener("keydown", keyPressedFn, false);
    };
  }, [keyPressedFn]);
  return (
    <BareModal
      isBgTransparent={isBgTransparent}
      widthClasses={widthClasses}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {title && (
        <div
          className={`flex border-b border-b-gray-200 sticky top-0 z-${modalZIndex} bg-white`}
        >
          <h2 className="text-sm leading-5 md:text-xl md:leading-7 font-bold text-gray-700 px-6 py-4 flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
            {title}
          </h2>
          <button
            className="px-6 text-green-700 hover:text-green-800"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="w-[18px] h-[28px]" />
          </button>
        </div>
      )}
      <div className="p-4 md:p-6">{children}</div>
      {/* Footer content here */}
      {isShowingModalFooter &&
        (!onSave ? (
          <div
            className={`flex justify-${
              showButtonInMiddle ? "center" : "end"
            } py-4 px-6 shadow-2xl sticky left-0 right-0 bottom-0 bg-white`}
          >
            <button
              className="bg-green-700 rounded-md px-5 py-3 flex text-white font-semibold text-base leading-6"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {showContinueButton ? "Continue" : "Close"}
            </button>
          </div>
        ) : (
          <div
            className={`flex justify-end py-4 px-6 space-x-6 shadow-2xl sticky left-0 right-0 bottom-0 z-${modalZIndex} bg-white`}
          >
            <button
              className="border border-gray-300 rounded-md px-6 py-3 flex text-gray-700 text-base leading-6 font-normal bg-transparent hover:bg-gray-100 transition-colors"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </button>
            <button
              className="bg-green-700 rounded-md px-5 py-3 flex items-center justify-center text-white font-semibold text-base leading-6 disabled:bg-emerald-700 disabled:opacity-30"
              onClick={() => {
                onSave();
              }}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        ))}
    </BareModal>
  );
}

// Use BareModal for fully customized Modals
// I.e. without title and close button at the top.
// Otherwise, use Modal above.
export function BareModal({
  isBgTransparent,
  widthClasses,
  isOpen,
  setIsOpen,
  children,
}: {
  isBgTransparent?: boolean;
  widthClasses?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children?: ReactNode;
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 w-full h-screen grid grid-cols-1 sm:place-content-center justify-center overflow-y-auto py-8 z-${modalZIndex}`}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className="fixed inset-0 h-screen bg-gray-500 bg-opacity-75 overflow-hidden"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className={classNames(
              "text-black rounded-lg text-left shadow-xl relative overflow-hidden mx-auto overflow-y-auto scrollbar",
              isBgTransparent ? "bg-transparent" : "bg-white",
              widthClasses ? widthClasses : "w-full max-w-[815px]"
            )}
          >
            {children}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
