import { Disclosure } from "@headlessui/react";
import Modal from "../modal/Modal";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { useQuery } from "react-query";
import { PlatinumStatusModalQueryKey, getUserPlatinumStatusDetails } from "./PlatinumStatusModal.fetchers";
import Avatar from "../Avatar";
import Link from "next/link";
import { Spinner } from "../spinner/Spinner";

export const PlatinumStatusModal = ({ 
  isPlatinumStatusModalOpen,
  setIsPlatinumStatusModalOpen,
  userId,
  isPlatinumStatusUser,
 }: { isPlatinumStatusModalOpen: boolean;
  setIsPlatinumStatusModalOpen: (isOpen: boolean) => void;
  userId: string;
  isPlatinumStatusUser: boolean;
 }) => {
  const { data: platinumStatusOrgData, isFetching } = useQuery({
    queryKey: [PlatinumStatusModalQueryKey.UserPlatinumStatusDetails, userId],
    queryFn: () => getUserPlatinumStatusDetails({ userId }),
    enabled: isPlatinumStatusModalOpen && isPlatinumStatusUser,
  })
  const platinumStatusQuestionsAndAnswers = [
    {
      question: "What perks do I get with Platinum status?",
      answer: (
        <div className="flex flex-col gap-3 text-coolGray-600 text-sm font-normal">
          <p>
            <span className="font-bold">Reduced minimums</span>. Platinum status
            investors have the option of investing at lowered minimums equal to
            50% of the stipulated minimum commitment. For example: if the
            minimum on the offering page is stated to be $50k, you will be able
            to invest $25k, should you prefer.
          </p>
          <p>
            <span className="font-bold">Priority allocation</span>. If a deal is
            oversubscribed, you will be first in line to receive allocation.
          </p>
          <p>
            <span className="font-bold">Early access to opportunities</span>.
            You will be amongst the first few to be notified when new
            opportunities become available.
          </p>
        </div>
      ),
    },
    {
      question: "How do I earn Platinum status?",
      answer: (
        <p className="self-stretch text-coolGray-600 text-sm font-normal">
          You can earn platinum status by making a referral via Elmbase. To
          qualify, the referral must be a first-time investor for the sponsor
          and invest in an opportunity.
        </p>
      ),
    },
    {
      question: "How do I know I’ve qualified for Platinum status? ",
      answer: (
        <p className="self-stretch text-coolGray-600 text-sm font-normal">
          Once qualified, your profile will be updated to include the platinum
          badge. You will also receive an email notification.
        </p>
      ),
    },
  ];
  return (
    <Modal
      isOpen={isPlatinumStatusModalOpen}
      setIsOpen={setIsPlatinumStatusModalOpen}
      title={null}
      showButtonInMiddle={true}
      showContinueButton={false}
      widthClasses="w-full sm:max-w-[600px]"
    >
      <div className="flex flex-col items-center gap-8 self-stretch">
        <div className="flex flex-col items-center gap-3">
          <div className="flex items-start">
            <Image
              src={"/icons/platinum_status.svg"}
              alt="Platinum status"
              width={60}
              height={60}
            />
          </div>
          <span className="w-[459px] text-coolGray-400 text-center text-3xl font-bold">
            Platinum Status
          </span>
        </div>
        <div className="flex flex-col items-start gap-4 self-stretch">
          {isPlatinumStatusUser ? (
            <div className="flex flex-col items-start gap-4">
              <p className="w-[536px] text-coolGray-600 text-sm font-medium">
                Congratulations, you’ve earned{" "}
                <span className="text-coolGray-400">Platinum</span> status with
                the following sponsors:
              </p>
              {isFetching && platinumStatusOrgData === undefined && (
                <div className="m-auto">
                  <Spinner color="green" />
                </div>
              )}
              {platinumStatusOrgData?.map((orgData, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 self-stretch"
                >
                  <Avatar
                    src={orgData.orgPicUrl!}
                    size={40}
                    firstName={orgData.orgName!}
                    lastName=""
                  />
                  <div className="flex flex-col items-start">
                    <Link
                      href={`/organization/${orgData.orgName}`}
                      className="text-gray-900 text-sm font-semibold"
                    >
                      {orgData.orgName}
                    </Link>
                    <span className="text-gray-500 text-xs font-medium">
                      Earned on {orgData.earnedOn}
                    </span>
                  </div>
                </div>
              ))}
              <p className="w-[536px] text-coolGray-600 text-sm font-medium">
                You are automatically eligible for special perks on offerings
                from the above sponsor(s).
              </p>
            </div>
          ) : (
            <div className="flex flex-col items-start gap-4 text-coolGray-600 text-sm font-medium">
              <p className="w-[536px]">
                <span className="text-coolGray-400">Platinum</span> status
                allows you to be eligible for special perks on offerings, for
                the sponsors you’ve earned status with.{" "}
              </p>
              <p className="w-[536px]">
                You have not yet earned{" "}
                <span className="text-coolGray-400">Platinum</span> status with
                any sponsors yet.
              </p>
            </div>
          )}
          <div className="flex flex-col items-start gap-2 self-stretch">
            {platinumStatusQuestionsAndAnswers.map((qa, index) => (
              <Disclosure key={index}>
                {({ open }) => (
                  <div className="flex p-3 flex-col items-start gap-3 self-stretch">
                    <Disclosure.Button className="flex items-center gap-2">
                      <span className="text-gray-700 text-sm font-medium underline">
                        {qa.question}
                      </span>
                      <FontAwesomeIcon
                        icon={open ? faChevronDown : faChevronRight}
                        className="text-gray-500 w-[14px] h-[14px]"
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="flex items-start">
                      {qa.answer}
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
