import { useEffect, useState } from "react";

export const GoogleAnalytics = () => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      setShouldRender(true);
    }
  }, []);

  return (
    <>
      {shouldRender && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-FDM4HQMW1N`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-FDM4HQMW1N');
                `,
            }}
          />
        </>
      )}
    </>
  );
};
