import React from "react";
// import { Tooltip } from "react-tooltip";
import { Tooltip } from "node_modules/react-tooltip/dist/react-tooltip.umd.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { classNames } from "../utils";

interface ElementWithTooltipProps {
  uniqueId: string;
  children: React.ReactNode;
  sourceElement?: React.ReactNode; // if not given, then shows an info icon
  header?: string;
  headerSize?: string;
  place?: string;
  hugContent?: boolean;
}

function InfoIcon() {
  return <FontAwesomeIcon icon={faCircleInfo} />;
}

export default function ElementWithTooltip({
  sourceElement = InfoIcon(),
  header,
  headerSize,
  place = "top",
  uniqueId,
  children,
}: ElementWithTooltipProps) {
  return (
    <>
      <div id={uniqueId} className="inline-flex items-center">
        {sourceElement}
      </div>
      <Tooltip
        anchorId={uniqueId}
        place={place}
        style={{
          backgroundColor: "#111827",
          maxWidth: "300px",
          zIndex: 1000,
          padding: 0,
          borderRadius: 8,
        }}
      >
        <div className={"space-y-2 px-3 py-2"}>
          {header && (
            <p
              className={classNames(
                headerSize ? headerSize : "text-sm",
                "font-medium text-gray-200"
              )}
            >
              {header}
            </p>
          )}
          <div className="text-sm leading-5 font-normal text-gray-200">
            {children}
          </div>
        </div>
      </Tooltip>
    </>
  );
}
