import { format } from "date-fns";
import { clientSupabase } from "www/shared/modules/supabase";

export enum PlatinumStatusModalQueryKey {
  UserPlatinumStatusDetails = "UserPlatinumStatusDetails",
}

export const getUserPlatinumStatusDetails = async ({
  userId
}: {userId: string}): Promise<{
    earnedOn: string;
    orgName: string | null;
    orgHandle: string;
    orgPicUrl: string | null;
}[]> => {
  const { data, error } = await clientSupabase
    .from("organization_platinum_users")
    .select("created_at, organizations(name, handle, profile_pic_url)")
    .eq("user_id", userId)
    .returns<
      | {
          created_at: string;
          organizations: {
            name: string | null;
            handle: string;
            profile_pic_url: string | null;
          };
        }[]
      | null
    >();
  if (error) {
    throw error;
  }
  if (!data) {
    return [];
  }

  return data.map((platinumUser) => {
    return {
      earnedOn: format(new Date(platinumUser.created_at), "MM/dd/yyyy"),
      orgName: platinumUser.organizations.name,
      orgHandle: platinumUser.organizations.handle,
      orgPicUrl: platinumUser.organizations.profile_pic_url,
    };
  })
};