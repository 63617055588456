import React from "react";
import { classNames } from "www/shared/utils";
import Link from "next/link";
import Avatar from "../Avatar";
import { formatRelativeDate } from "www/shared/utils/date_utils";

export type NotificationCardContainerProps = {
  id: number;
  profilePicUrl: string | null;
  name: string;
  time: string;
  isSeen: boolean;
  handle: string;
  children: React.ReactNode;
  isInMenu?: boolean;
};
export default function NotificationCardContainer({
  time,
  profilePicUrl,
  name,
  isSeen,
  handle,
  isInMenu = true,
  children,
}: NotificationCardContainerProps) {
  return (
    <div
      className={classNames(
        "pointer-events-auto w-full ring-opacity-5 hover:bg-green-100",
        isSeen ? "bg-white" : "bg-green-100",
        isInMenu
          ? "ring-1 ring-black"
          : "border-b border-black border-opacity-5"
      )}
    >
      <div
        className={classNames(
          "flex flex-col gap-3",
          isInMenu ? "p-4" : "p-4 md:pl-8 md:pr-9 md:py-5"
        )}
      >
        <div className="flex items-start">
          <Link href={handle} legacyBehavior>
            <Avatar
              src={profilePicUrl}
              size={30}
              firstName={name.split(" ")[0]}
              lastName={name.split(" ")[1]}
              className="pt-0"
            />
          </Link>
          <div className="ml-3 flex-1">
            <div className="flex items-center w-full justify-between">
              <Link href={handle} legacyBehavior>
                <p className="text-sm font-medium text-gray-900 cursor-pointer mb-1">
                  {name}
                </p>
              </Link>
              <span className="inline-flex sm:hidden rounded-md  text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-xs leading-4 font-normal">
                {time && formatRelativeDate({ dateString: time })}
              </span>
            </div>
            <div className="hidden sm:block">{children}</div>
          </div>
          <div className="ml-4 hidden sm:flex flex-shrink-0">
            <span className="inline-flex rounded-md  text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-xs leading-4 font-normal">
              {time && formatRelativeDate({ dateString: time })}
            </span>
          </div>
        </div>
        <div className="sm:hidden">{children}</div>
      </div>
    </div>
  );
}
