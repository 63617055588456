import React, { useEffect, useMemo, useState } from "react";
import { ProfilePageView } from "types/views";
import { Control, useForm, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faImage } from "@fortawesome/pro-regular-svg-icons";
import { useDropzone, FileWithPath } from "react-dropzone";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQueryClient } from "react-query";
import { invalidateProfileViews } from "./Profile.fetchers";
import { updateProfile } from "./EditProfileModal.fetchers";
import classNames from "www/shared/utils/classNames";
import Modal from "www/shared/components/modal/Modal";
import { invalidateAppQuery } from "www/app/App.fetchers";
import TextInput from "www/shared/components/form_inputs/TextInput";
import TextArea from "www/shared/components/form_inputs/TextArea";
import { useTour } from "@reactour/tour";
import { yupResolver } from "@hookform/resolvers/yup";
import { PostgrestError } from "@supabase/supabase-js";
import { editProfileModalSchema } from "./EditProfileModal.schema";
import Image from "next/image";
import { useRouter } from "next/router";
import { getUserDomain } from "www/shared/utils/handleDomains";

interface EditProfileModalProps {
  profile: ProfilePageView;
  isOpen: boolean;
  setIsOpen: (modal: boolean) => void;
  referral?: boolean;
}
export interface ProfileFormInputType {
  first_name?: string | null;
  last_name?: string | null;
  handle?: string | null;
  subtitle?: string | null;
  about?: string | null;
  facebook_url?: string | null;
  instagram_url?: string | null;
  linkedin_url?: string | null;
  twitter_url?: string | null;
  profile_pic_url?: string | null;
  cover_photo_url?: string | null;
  phone_number?: string | null;
  phone_number_required: boolean;
}

// Calculating input lengths
function InputLength({
  control,
  name,
  limit,
}: {
  control: Control<ProfileFormInputType>;
  name: "about" | "subtitle";
  limit: number;
}) {
  let aboutLength = useWatch({
    control,
    name: name,
  })?.length;

  return (
    <p className="mt-1  text-gray-500 text-sm leading-5 font-normal">
      {aboutLength ?? 0}/{limit} Characters
    </p>
  );
}

const EditProfileModal = ({
  profile,
  isOpen,
  setIsOpen,
  referral,
}: EditProfileModalProps) => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [coverImage, setCoverImage] = useState<FileWithPath>();
  const [profileImage, setProfileImage] = useState<File>();
  const queryClient = useQueryClient();
  const { setIsOpen: setIsTourOpen } = useTour();
  const { pathname } = useRouter();

  const updateProfileMutation = useMutation(updateProfile, {
    onSuccess: () => {
      toast.success("Profile updated successfully");
      invalidateProfileViews(queryClient);
      invalidateAppQuery(queryClient);
      setIsOpen(false);
      // after 3 seconds, show tour
      if (referral && pathname.startsWith("/deal/")) {
        setTimeout(() => {
          setIsTourOpen(true);
        }, 2000);
      }
    },
    onError: (error: PostgrestError) => {
      toast.error("Error updating profile", {
        hideProgressBar: true,
      });

      console.log("Error", error);
    },
  });
  const { getRootProps, getInputProps } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDragEnter: () => {
      setDragging(true);
    },
    onDragOver: () => {
      setDragging(true);
    },
    onDragLeave: () => {
      setDragging(false);
    },
    onDrop(acceptedFiles) {
      if (acceptedFiles.length > 0) {
        setCoverImage(acceptedFiles[0]);
      }
    },
  });

  const isPhoneNumberRequired =
    profile.country_domains?.includes("India") ?? false;

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    trigger,
    resetField,
    formState: { errors },
  } = useForm<ProfileFormInputType>({
    reValidateMode: "onChange",
    resolver: isOpen ? yupResolver(editProfileModalSchema) : undefined,
    defaultValues: useMemo(
      () => ({
        first_name: profile.first_name,
        last_name: profile.last_name,
        handle: referral
          ? profile.handle
          : profile.handle || `${profile.first_name}${profile.last_name}`,
        subtitle: profile.subtitle,
        about: profile.about,
        facebook_url: profile.facebook_url,
        instagram_url: profile.instagram_url,
        linkedin_url: profile.linkedin_url,
        twitter_url: profile.twitter_url,
        profile_pic_url: profile.profile_pic_url,
        cover_photo_url: profile.cover_photo_url,
        phone_number: profile.phone_number,
        phone_number_required: isPhoneNumberRequired,
      }),
      [profile, isPhoneNumberRequired, referral]
    ),
  });

  useEffect(() => {
    resetField("phone_number_required", {
      defaultValue: isPhoneNumberRequired,
    });
  }, [isPhoneNumberRequired, resetField]);

  const watchProfilePics = watch("profile_pic_url");
  const watchCoverPics = watch("cover_photo_url");

  const updateProfileFunction = async () => {
    updateProfileMutation.mutate({
      formData: {
        ...getValues(),
      },
      profilePhotoLink: watchProfilePics,
      coverPhotoLink: watchCoverPics,
      profileImage: profileImage,
      coverImage: coverImage,
      userId: profile.user_id as string,
    });
  };

  const setIsOpenWithCheck = async (val: boolean) => {
    const response = await trigger();
    if (val === true) {
      setIsOpen(true);
    } else if (val === false) {
      if (!response) {
        toast.error("Please fill out the required fields");
      } else {
        setIsOpen(false);
      }
    }
  };
  const domain = getUserDomain(profile.country_domains);
  return (
    <Modal
      widthClasses="w-full sm:max-w-[815px]"
      isOpen={isOpen}
      setIsOpen={setIsOpenWithCheck}
      title="Edit Profile"
      isSaving={updateProfileMutation.isLoading}
      onSave={handleSubmit(
        () => {
          updateProfileFunction();
        },
        (e) => {
          toast.error("Please fix errors before submitting.");
          console.log(e);
        }
      )}
    >
      <div className={classNames("h-[83.33%] overflow-auto text-gray-700")}>
        {referral && (
          <div className="w-full bg-green-100 border-l-4  pl-6 pr-4 py-3 border-l-green-700 mb-4">
            <p className="text-sm leading-5 font-medium">
              <span className="font-bold">Welcome to Elmbase!</span> Please
              finish setting up your user profile below. You&apos;ll be able to
              view the full deal details and refer your friends once you&apos;ve
              completed this step.
            </p>
          </div>
        )}
        {/* .File upload section */}
        <form>
          <div
            // className="sm:col-span-6 relative mb-12"
            {...getRootProps({
              className: "dropzone sm:col-span-6 relative mb-12",
            })}
          >
            <div
              className={classNames(
                dragging ? "border-green-500" : "border-gray-300",
                "mt-1 flex justify-center px-6 pt-5 pb-6 bg-gray-200 hover:bg-gray-300 border rounded-md min-h-[140px] relative"
              )}
              style={{
                backgroundImage: `url(${
                  coverImage
                    ? URL.createObjectURL(coverImage)
                    : profile.cover_photo_url
                })`,
                backgroundSize: "cover",
              }}
            >
              <input {...getInputProps()} />
              <label
                htmlFor="cover-photo"
                className="absolute top-[18px] right-[18px] group "
              >
                {(profile.cover_photo_url || coverImage) && (
                  <div className="w-8 h-8 rounded-full bg-white z-40  shadow-md flex justify-center items-center group-hover:bg-gray-200 cursor-pointer">
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      size="lg"
                      className="text-green-600 group-hover:text-green-400"
                    />
                  </div>
                )}
              </label>
              <div
                className={classNames(
                  profile.cover_photo_url || coverImage ? "hidden" : "block",
                  "space-y-1 text-center"
                )}
              >
                <FontAwesomeIcon icon={faImage} className="w-9 h-6" />
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="cover-photo"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-green-700 hover:text-green-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="cover-photo"
                      name="cover-photo"
                      type="file"
                      className="sr-only"
                      onChange={(e) => {
                        if (e.target.files) {
                          setCoverImage(e.target.files[0]);
                        }
                      }}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
            {/* Photo section */}
            <div className="sm:col-span-6 ml-4 relative -mt-10 h-20 w-20 bg-white rounded-full">
              <div className="flex items-center  relative">
                <span className="h-20 w-20 rounded-full overflow-hidden bg-white  relative border-green-white border-[3px]">
                  <input
                    id="profile-pics"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={(e) => {
                      if (e.target.files) {
                        setProfileImage(e.target.files[0]);
                      }
                    }}
                  />

                  {watchProfilePics !== null || profileImage ? (
                    <Image
                      src={
                        profileImage
                          ? URL.createObjectURL(profileImage)
                          : watchProfilePics
                          ? watchProfilePics
                          : ""
                      }
                      alt="profile pic"
                      height={80}
                      width={80}
                      className="w-[80px] h-[80px] object-cover "
                    />
                  ) : (
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  )}
                </span>
                <label htmlFor="profile-pics" className="group">
                  <div className="w-8 h-8 rounded-full bg-white z-40 absolute -right-1 bottom-0 shadow-md flex justify-center items-center group-hover:bg-gray-200 cursor-pointer">
                    <FontAwesomeIcon
                      icon={faCamera}
                      size="lg"
                      className=" text-green-600 group-hover:text-green-400"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* First name and last name section */}
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <TextInput
                variant="default"
                required
                label="First name"
                placeholder="First name"
                containerClassName=""
                error={errors.first_name ? true : false}
                errorMessage={errors.first_name?.message}
                autoComplete="given-name"
                {...register("first_name")}
              />
            </div>

            <div className="sm:col-span-3">
              <TextInput
                variant="default"
                required
                label="Last name"
                placeholder="Last name"
                containerClassName=""
                error={errors.last_name ? true : false}
                errorMessage={errors.last_name?.message}
                autoComplete="given-name"
                {...register("last_name")}
              />
            </div>
          </div>
          {/* Profile Handle */}
          <div className="mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 w-full">
            <div className="sm:col-span-6">
              <TextInput
                variant="add-on"
                required
                label="Handle"
                placeholder="handle"
                containerClassName=""
                addOn={`${domain}/p/`}
                error={
                  errors.handle ||
                  updateProfileMutation.error?.message.includes(
                    "user_profiles_handle_key"
                  )
                    ? true
                    : false
                }
                errorMessage={
                  errors.handle?.message
                    ? errors.handle?.message
                    : updateProfileMutation.error?.message.includes(
                        "user_profiles_handle_key"
                      )
                    ? "This handle is already taken."
                    : ""
                }
                {...register("handle")}
              />
            </div>
          </div>
          {/* Headline */}
          <div className="sm:col-span-6 mt-5">
            <TextInput
              variant="default"
              required
              label="Headline (a one-liner about you)"
              placeholder="Investor looking to grow my portfolio"
              containerClassName=""
              error={errors.subtitle ? true : false}
              errorMessage={errors.subtitle?.message}
              {...register("subtitle", { required: true, maxLength: 100 })}
            />
            <InputLength control={control} name="subtitle" limit={100} />
          </div>
          {/* Phone Number */}
          <div className="mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 w-full">
            {/* Hidden field to help us conditionally make phine number required */}
            <input hidden {...register("phone_number_required")} />

            <div className="sm:col-span-6">
              <TextInput
                required={isPhoneNumberRequired}
                variant="default"
                label="Phone number (not shown on profile)"
                placeholder=""
                containerClassName=""
                error={errors.phone_number ? true : false}
                errorMessage={errors.phone_number?.message}
                {...register("phone_number")}
              />
            </div>
          </div>
          {/* About */}
          <div className="sm:col-span-6 mt-5">
            <TextArea
              label="About"
              placeholder="Describe yourself to other users here. Consider answering: what are your real estate interests? What are you looking for? Ex: I'm a real estate investor looking to grow my portfolio. I'm interested in multi-family properties in the midwest."
              containerClassName=""
              rows={3}
              {...register("about", { maxLength: 2000 })}
            />
            <InputLength control={control} name="about" limit={2000} />
          </div>

          {/* LinkedIN */}
          <div className="mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 w-full">
            <div className="sm:col-span-6">
              <TextInput
                variant="default"
                label="Linkedin Profile"
                placeholder=""
                containerClassName=""
                autoComplete="linkedin"
                {...register("linkedin_url")}
              />
            </div>
          </div>
          {/* Twitter */}
          <div className="mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 w-full">
            <div className="sm:col-span-6">
              <TextInput
                variant="default"
                label="Twitter Profile"
                placeholder=""
                containerClassName=""
                autoComplete="twitter"
                {...register("twitter_url")}
              />
            </div>
          </div>
          {/* Instagram */}
          <div className="mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 w-full">
            <div className="sm:col-span-6">
              <TextInput
                variant="default"
                label="Instagram Profile"
                placeholder=""
                containerClassName=""
                autoComplete="instagram"
                {...register("instagram_url")}
              />
            </div>
          </div>
          {/* Facebook */}
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 w-full mb-4">
            <div className="sm:col-span-6">
              <TextInput
                variant="default"
                label="Facebook Profile"
                placeholder=""
                containerClassName=""
                autoComplete="facebook"
                {...register("facebook_url")}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
