import axios, { AxiosResponse } from "axios";
import { ProfileApiResponse, PublicProfilePageView } from "pages/api/profile";
import { QueryClient } from "react-query";
import { ProfilePagePublicView, ProfilePageView } from "types/views";
import { clientSupabase } from "www/shared/modules/supabase";

export enum ProfileQueryKey {
  ProfileView = "ProfileView",
  SponsorProfilePublicView = "SponsorProfilePublicView",
}

const fetchProfilePageView = async (handle: string) => {
  const { data, error } = await clientSupabase
    .from("profile_page_for_cur_user_view")
    .select()
    .eq("handle", handle as string)
    .limit(1)
    .returns<ProfilePageView[]>();

  if (error) {
    throw error;
  }
  if (data && data.length > 0) {
    return {
      full_profile: data[0],
      public_profile: null,
    };
  }
  return null;
};

export type ProfilePageData =
  | {
      full_profile: ProfilePageView;
      public_profile: null;
    }
  | {
      full_profile: null;
      public_profile: PublicProfilePageView;
    }
  | null;

export const fetchProfileView = async ({
  handle,
}: {
  handle?: string;
}): Promise<ProfilePageData> => {
  if (!handle) {
    return null;
  }

  // call fetchProfilePageView
  // if data, return data, otherwise call axios.get(`/api/profile/${handle}`)
  // if 404, throw error, otherwise return data
  const response = await fetchProfilePageView(handle);

  if (response) {
    // return the resolved promise response
    return response;
  }

  const profileApiResponse: AxiosResponse<ProfileApiResponse> =
    await axios.post(`/api/profile`, { handle });
  if (profileApiResponse.status === 404) {
    throw new Error("Something went wrong");
  }
  if (
    profileApiResponse.data.status === "NOT_FOUND" ||
    !profileApiResponse.data.profile
  ) {
    return null;
  }
  return {
    full_profile: null,
    public_profile: profileApiResponse.data.profile,
  };
};

export const fetchSponsorProfilePublicView = async ({
  handle,
}: {
  handle: string;
}): Promise<ProfilePagePublicView | null> => {
  const profileApiResponse: AxiosResponse<ProfilePagePublicView | null> =
    await axios.post(`/api/public_profile`, { handle });
  if (profileApiResponse.status === 404) {
    throw new Error("Something went wrong");
  }
  if (!profileApiResponse.data) {
    return null;
  }
  return profileApiResponse.data;
};

export const invalidateProfileViews = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(ProfileQueryKey.ProfileView);
};
