"use client";
import type { AppProps } from "next/app";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import CustomErrorComponent from "pages/_error";
import { ReactNode, useEffect } from "react";
import MainLayout from "www/shared/components/layout";
import { NavBar } from "www/shared/components/layout/NavBar";
import {
  useGlobalState,
  useProcessLogout,
} from "www/shared/modules/global_context";
import { clientSupabase } from "www/shared/modules/supabase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "simplebar-react/dist/simplebar.min.css";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  addDealsToPortfolio,
  addTransactionsToPortfolioDeals,
  AppQueryKey,
  doesUserHaveAccessToADeal,
  fetchDealCommitments,
  fetchSponsorDeals,
  fetchUserPortfolio,
  fetchUserPreferences,
  fetchUserProfile,
  fetchUserProfileOverrides,
  getLekkoConfigs,
  ingestPortfolioInvestmentsForUser,
  setUserProfileFromGoogleAuth,
} from "./App.fetchers";
import { useState } from "react";

import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import Head from "next/head";
import { ProfilePageView } from "types/views";
import LoadingIndicator from "www/shared/components/LoadingIndicator";
import DealTourProvider from "www/pages/deal/TourProvider";
import {
  domains,
  shouldBypassDomainCheck,
} from "www/shared/utils/handleDomains";
import { CountryEnum } from "www/shared/utils/CountryEnum";
import { GoogleAnalytics } from "www/shared/components/layout/analytics/GoogleAnalytics";

// Needed for older versions of Chrome (89-91) that don't have Array.at implemented yet.
// PDF.js uses Array.at, so we need to polyfill it using this library.
import "array.prototype.at/shim";
import EmailDesignLayout from "www/shared/components/layout/EmailDesignLayout";
import { NextComponentType, NextPageContext } from "next";
import * as Sentry from "@sentry/nextjs";
import { PortfolioDashboardQueryKey } from "app/dashboard/Dashboard.fetchers";
import { PortfolioDealTransactionType } from "app/dashboard/Dashboard.types";

import { LekkoClientProvider } from "@lekko/next-sdk";
import HeapAnalytics from "www/shared/components/layout/analytics/HeapAnalytics";

const public_pages = ["/", "/sentry_sample_error"];
const public_routes: string[] = [
  "/deal",
  "/privacy_policy",
  "/terms_of_service",
  "/sponsors",
  "/investors",
  "/organization",
  "/p/",
  "/feed"
];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true, // we can turn this off after we are done with development
    },
  },
});

// Main Component, all other components fall under this component
export default function MyApp({ Component, pageProps }: AppProps) {
  return <GenericLayout Component={Component} pageProps={pageProps} />;
}

// We use this component to re-suse logic between app and page layout
export function GenericLayout({
  Component,
  pageProps,
  children,
}: {
  Component?: NextComponentType<NextPageContext, any, any>;
  children?: ReactNode;
  pageProps?: any;
}) {
  const router = useRouter();
  const path = usePathname();
  const searchParams = useSearchParams();
  const supabaseUser = useGlobalState((s) => s.supabaseUser);
  const setGlobalState = useGlobalState((s) => s.setGlobalState);

  useEffect(() => {
    const getUserWrapper = async () => {
      const {
        data: { session },
      } = await clientSupabase.auth.getSession();

      if (!session || !session?.user) {
        setGlobalState({ supabaseUser: null });
      } else {
        // The user is not set immediately on refresh
        setGlobalState({ supabaseUser: session?.user });
      }
    };
    getUserWrapper();
  }, [setGlobalState]);

  useEffect(() => {
    // Handle error returned back from Google Oauth here
    const error = searchParams?.get("error");
    const errorDescription = searchParams?.get("error_description");
    if (error) {
      if (errorDescription === "Database error saving new user") {
        toast.error("There is already a user with that email.");
      } else {
        toast.error("There was an error logging in with Google.");
        Sentry.captureException(`Error logging in user with Google: ${error}`);
      }
    }
  }, [searchParams]);

  // this runs when you refresh the page and you are logged in for some reason, probably because the supabaseUser isn't yet ready and hasn't been
  // loaded yet, hence commenting out
  // useEffect(() => {
  //   if (!supabaseUser && !isPublicPage) {
  //     console.log(
  //       "No logged in user, and is attempting to go to non-public page, redirecting to /"
  //     );
  //     push("/");
  //   }
  // });

  // const [componentToLoad, setComponentToLoad] = useState(() => (
  //   <LoadingIndicator />
  // ));
  const [loadComponentOnly, setLoadComponentOnly] = useState(false);

  // Only deal with asPath without hashes or queries
  // This prevents a hydration error when
  const isPublicPage =
    !!path &&
    (public_pages.includes(path) ||
      !!public_routes.find((r) => path.includes(r)));
  const isEmailPage = !!path && path.includes("/emails/");
  const isEmailTemplateDesignPage =
    !!path && path.includes("/account/campaigns/design");
  let componentToLoad = <CustomErrorComponent statusCode={404} />;
  const isInvestorDashboardPage = !!path && path.includes("/dashboard");

  useEffect(() => {
    if (path === "/" && supabaseUser) {
      router.push("/feed");
    }
  }, [path, supabaseUser]);

  if (supabaseUser === undefined) {
    componentToLoad = <LoadingIndicator />;
  } else if (isEmailPage && Component) {
    componentToLoad = <Component {...pageProps} />;
  } else if (supabaseUser && Component) {
    componentToLoad = <Component {...pageProps} />;
  } else if (!supabaseUser && isPublicPage && Component) {
    componentToLoad = <Component {...pageProps} />;
  }

  // setComponentToLoad(componentToLoad);
  // print name of the component that is being loaded
  // console.log("setting component to load: ", Component.name);
  useEffect(() => {
    if (isEmailPage) {
      setLoadComponentOnly(true);
    }
  }, [isEmailPage]);

  if (loadComponentOnly) {
    return componentToLoad;
  }

  return (
    <>
      <Head>
        <title>
          Elmbase | A professional network for private real estate offerings |
          Real Estate Syndication
        </title>
        <meta
          name="description"
          content="Elmbase is a platform discover private real estate offerings, for real estate investors and syndicators alike. Join the waitlist today!"
        />
        <meta
          property="og:title"
          content="Elmbase | A professional network for real estate offerings | Real Estate Syndication"
        />
        <meta
          property="og:description"
          content="Elmbase is a platform discover private real estate offerings, for real estate investors and syndicators alike. Join the waitlist today!"
        />
        {/* <meta
          property="og:image"
          content="https://example.com/images/cool-page.jpg"
        /> */}
        <GoogleAnalytics />
      </Head>
      <QueryClientProvider client={queryClient}>
        <div id="root"></div>
        {/* Root element is needed for Google Maps embed */}
        <div id="calendly-root-white-bg"></div>
        <div id="calendly-root"></div>{" "}
        <LekkoConfigsProvider>
          <CurrentUserInfoProvider>
            <HeapAnalytics />
            <DealTourProvider>
              {isEmailTemplateDesignPage ? (
                <EmailDesignLayout>
                  {componentToLoad}
                  <ToastContainer />
                </EmailDesignLayout>
              ) : (
                <MainLayout
                  navBar={isInvestorDashboardPage ? undefined : <NavBar />}
                >
                  {isInvestorDashboardPage ? children : componentToLoad}
                  <ToastContainer />
                </MainLayout>
              )}
            </DealTourProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </CurrentUserInfoProvider>
        </LekkoConfigsProvider>
      </QueryClientProvider>
    </>
  );
}

function LekkoConfigsProvider({ children }: { children: ReactNode }) {
  const [lekkoConfigs, setLekkoConfigs] = useState<string | null>(null);
  useQuery({
    queryKey: [AppQueryKey.FetchLekkoConfigs],
    queryFn: getLekkoConfigs,
    onSuccess: (lekkoConfigs) => {
      setLekkoConfigs(lekkoConfigs);
    },
  });

  return (
    <LekkoClientProvider configs={lekkoConfigs}>{children}</LekkoClientProvider>
  );
}

function CurrentUserInfoProvider({ children }: { children: ReactNode }) {
  const { supabaseUser, userProfile, setGlobalState } = useGlobalState();
  const searchParams = useSearchParams();
  const path = usePathname();
  const googleAccessToken = searchParams?.get("code");
  // Query to set sponsorDeals in global state
  useQuery({
    queryKey: [AppQueryKey.SponsorDeals, supabaseUser?.id],
    queryFn: () => {
      if (!supabaseUser) return [];
      return fetchSponsorDeals(supabaseUser?.id!);
    },
    onSuccess: (data) => {
      if (!data) return;
      let deals = [...data];

      // Sort by active deals first, then by latest created_at
      deals.sort((a, b) => {
        if (a.is_active !== b.is_active) {
          return a.is_active ? 1 : -1;
        }

        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      });

      setGlobalState({ sponsorDeals: data });
    },
    enabled: !!supabaseUser?.id,
  });

  // Query to set userProfile in global state
  const userProfileQuery = useQuery({
    queryKey: [AppQueryKey.UserProfile, supabaseUser?.id],
    queryFn: () => {
      if (!supabaseUser || !supabaseUser.id) return null;
      return fetchUserProfile(supabaseUser.id);
    },
    onSuccess: (userProfile) => {
      if (!userProfile) return;

      if (
        !userProfile.first_name ||
        !userProfile.last_name ||
        !userProfile.handle
      ) {
        console.log("user profile is missing some fields!");
        // Give the user the ability to edit what we pulled from Google, also for India users
        setGlobalState({
          isUpdateModalOpen: true,
          userProfile: userProfile,
        });
      } else {
        setGlobalState({
          userProfile: userProfile,
        });
      }
    },
    enabled: !!supabaseUser?.id,
  });

  // Query to set userPreferences in global state
  const userPreferencesQuery = useQuery({
    queryKey: [AppQueryKey.UserPreferences, supabaseUser?.id],
    queryFn: () => {
      if (!supabaseUser || !supabaseUser.id) return null;
      return fetchUserPreferences(supabaseUser.id);
    },
    onSuccess: (data) => {
      if (!data) return;
      setGlobalState({
        userPreferences: data,
      });
    },
    enabled: !!supabaseUser?.id,
  });

  // Query to set userProfileOverrides in global state
  const userProfileOverridesQuery = useQuery({
    queryKey: [AppQueryKey.UserProfileOverrides, supabaseUser?.id],
    queryFn: () => {
      if (!supabaseUser || !supabaseUser.id) return null;
      return fetchUserProfileOverrides(supabaseUser.id);
    },
    onSuccess: (data) => {
      if (!data) return;
      setGlobalState({
        userProfileOverrides: data,
      });
    },
    enabled: !!supabaseUser?.id,
  });

  const processLogout = useProcessLogout();
  // kinda hacky, but here we want to prevent users that aren't invited from logging into the platform
  // the exception is if the user is viewing a /deal page.
  useQuery({
    queryKey: [AppQueryKey.UserCheck, supabaseUser?.id],
    queryFn: () => {
      if (!supabaseUser || !supabaseUser.id) return null;
      // console.log(
      //   `querying... ${asPath} ${supabaseUser?.id} ${userProfileQuery.isFetched} ${userProfileQuery.data?.user_id} ${userProfileQuery.data?.current_org_id}}`
      // );
      return doesUserHaveAccessToADeal();
    },
    onSuccess: (hasAccess) => {
      // console.log("checking access!");
      if (!hasAccess && !path?.includes("/deal/")) {
        // console.log(
        //   `checking access! ${hasAccess} ${asPath} ${supabaseUser?.id} ${userProfileQuery.isFetched} ${userProfileQuery.data?.user_id} ${userProfileQuery.data?.current_org_id}}`
        // );
        // Friendly error message
        toast.info(
          "Elmbase is currently invite-only. Please add yourself to the waitlist to be invited soon for early access 🙏"
        );
        processLogout();
      }
    },
    enabled:
      !!supabaseUser?.id &&
      !path?.includes("/deal/") &&
      userPreferencesQuery.isFetched &&
      userProfileOverridesQuery.isFetched &&
      userProfileQuery.isFetched &&
      !userProfileQuery.data?.current_org_id,
  });

  const setUserProfileIfSigningInWithGoogle = useMutation(
    async ({
      accessToken,
      userProfile,
    }: {
      accessToken: string;
      userProfile: ProfilePageView;
    }) => {
      return setUserProfileFromGoogleAuth(accessToken, userProfile);
    },
    {
      onSuccess: (data) => {
        console.log(data);

        // Give the user the ability to edit what we pulled from Google, also for India users
        setGlobalState({
          isUpdateModalOpen: true,
        });

        // invalidate all queries with the current user id, to force a refetch of the data
        queryClient.invalidateQueries(AppQueryKey.UserProfile);
      },
    }
  );
  useEffect(() => {
    if (userProfile) {
      const domain = window.location.hostname;
      const country = Object.keys(domains).find((key) =>
        domains[key as CountryEnum].includes(domain)
      ) as CountryEnum;
      // if (country) {
      if (
        userProfile?.country_domains &&
        userProfile.country_domains.length > 0
      ) {
        if (!userProfile.country_domains.includes(country)) {
          const shouldBypass = shouldBypassDomainCheck(domain);
          const redirectDomain = domains[userProfile.country_domains[0]][0];

          const redirectUrl = `https://${redirectDomain}${path}`;

          if (!shouldBypass) {
            toast.info(
              `Please login via ${redirectDomain}. You will be automatically redirected in 5 seconds.`
            );
            processLogout();
            setTimeout(() => {
              window.location.href = redirectUrl;
            }, 5000);
          } else {
            console.log(
              `we're in deploy preview, so not redirecting to ${redirectUrl}`
            );
          }
        }
      }
    }
  }, [path, processLogout, userProfile]);

  // This query is used to set the user profile if the user is logging in with Google; we'll get the code from the url
  if (
    googleAccessToken &&
    userProfile &&
    setUserProfileIfSigningInWithGoogle.isIdle &&
    setUserProfileIfSigningInWithGoogle.failureCount === 0
  ) {
    if (
      !userProfile.first_name ||
      !userProfile.last_name ||
      !userProfile.profile_pic_url ||
      !userProfile.handle
    )
      setUserProfileIfSigningInWithGoogle.mutate({
        accessToken: googleAccessToken,
        userProfile: userProfile,
      });
  }

  const { data: portfolio } = useQuery({
    queryKey: [AppQueryKey.UserPortfolio, userProfile?.user_id],
    queryFn: () => fetchUserPortfolio({ userId: userProfile?.user_id! }),
    enabled: !!userProfile?.user_id,
  });

  const addDealsToPortfolioMutation = useMutation(addDealsToPortfolio, {
    onSuccess: () => {
      queryClient.invalidateQueries([PortfolioDashboardQueryKey.Portfolio]);
    },
    onError: (error) => {
      console.log(
        "Something went wrong in syncing deals to the user's portfolio."
      );
      Sentry.captureException(error, {
        extra: {
          message:
            "Something went wrong in syncing deals to the user's portfolio.",
        },
      });
    },
  });

  const addTransactionsToPortfolioDealsMutation = useMutation(
    addTransactionsToPortfolioDeals,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PortfolioDashboardQueryKey.Portfolio]);
      },
      onError: (error) => {
        console.log(
          "Something went wrong in adding transactions to the user's portfolio deals."
        );
        Sentry.captureException(error, {
          extra: {
            message:
              "Something went wrong in adding transactions to the user's portfolio deals.",
          },
        });
      },
    }
  );

  useQuery({
    queryKey: [AppQueryKey.IngestPortfolioInvestmentsForUser, supabaseUser?.id],
    queryFn: () =>
      ingestPortfolioInvestmentsForUser({
        email: supabaseUser?.email!,
        portfolioId: portfolio?.id!,
      }),
    enabled: !!supabaseUser?.id && !!portfolio?.id && !portfolio?.portfolio_deals?.length,
    onSuccess: () => {
      queryClient.invalidateQueries([PortfolioDashboardQueryKey.Portfolio]);
    }
  });

  // Get the deals the user has access to and their statuses, as well as their entire portfolio
  useQuery({
    queryKey: [AppQueryKey.FetchAllDealCommitments, supabaseUser?.id],
    queryFn: fetchDealCommitments,
    enabled: !!supabaseUser?.id && !!portfolio?.id,
    onSuccess: (dealCommitments) => {
      const dealsWhereUserIsNotSponsor = dealCommitments.filter(
        (deal) =>
          deal.deal_sponsors?.every(
            (sponsor) => sponsor.user_id !== supabaseUser?.id
          ) ?? false
      );
      const dealsToAdd = dealsWhereUserIsNotSponsor.filter((deal) =>
        portfolio?.portfolio_deals?.every(
          (pd) => pd.elmbase_deal_id !== deal.id
        )
      );

      if (dealsToAdd.length > 0) {
        addDealsToPortfolioMutation.mutate({
          deals: dealsToAdd,
          portfolioId: portfolio?.id!,
        });
      }

      const transactionsToAdd = dealsWhereUserIsNotSponsor
        .filter((deal) =>
          portfolio?.portfolio_deals?.find(
            (pd) =>
              pd.elmbase_deal_id === deal.id &&
              !!deal.has_completed_investment &&
              deal.firm_committed_amount !== null &&
              !pd.transactions?.length
          )
        )
        .map((deal) => ({
          portfolio_deal_id: portfolio?.portfolio_deals?.find(
            (pd) => pd.elmbase_deal_id === deal.id
          )?.id!,
          transaction_type:
            "InitialContribution" as PortfolioDealTransactionType,
          amount: deal.firm_committed_amount!,
          actual_date: deal.funds_due_date!,
        }));
      if (transactionsToAdd.length > 0) {
        addTransactionsToPortfolioDealsMutation.mutate({
          transactionData: transactionsToAdd,
        });
      }
    },
  });

  return <>{children}</>;
}
