import { formatInTimeZone } from "date-fns-tz";
import { format } from "date-fns";

export function formatRelativeDate({
  dateString,
  showFullText = false,
}: {
  dateString: string;
  showFullText?: boolean;
}) {
  if (dateString === undefined) return "NaN";
  const date1 = new Date(dateString);
  const date2 = new Date();

  const diff = date2.getTime() - date1.getTime();
  const seconds = diff / 1000;

  let relativeDate = "";
  if (diff < 0) {
    // If the date is in the future
    if (-seconds < 60) {
      relativeDate = `${showFullText ? "Just now" : "<1 m"}`;
    } else if (-seconds < 3600) {
      const minutes = Math.round(-seconds / 60);
      relativeDate = showFullText
        ? `${minutes} min${minutes > 1 ? "s" : ""}`
        : `${minutes} m`;
    } else if (-seconds < 86400) {
      const hours = Math.round(-seconds / 3600);
      relativeDate = showFullText
        ? `${hours} hour${hours > 1 ? "s" : ""}`
        : `${hours} h`;
    } else if (-seconds < 604800) {
      const days = Math.round(-seconds / 86400);
      relativeDate = showFullText
        ? `${days} day${days > 1 ? "s" : ""}`
        : `${days} d`;
    } else if (-seconds < 2592000) {
      const weeks = Math.round(-seconds / 604800);
      relativeDate = showFullText
        ? `${weeks} week${weeks > 1 ? "s" : ""}`
        : `${weeks} w`;
    } else if (-seconds < 31536000) {
      const months = Math.round(-seconds / 2592000);
      relativeDate = showFullText
        ? `${months} month${months > 1 ? "s" : ""}`
        : `${months} mo`;
    } else {
      relativeDate = getRelativeDateInyears({ seconds: -seconds, showFullText });
    }
  } else {
    // If the date is in the past
    if (seconds < 60) {
      relativeDate = `${showFullText ? "Just now" : "<1m ago"}`;
    } else if (seconds < 3600) {
      const minutes = Math.round(seconds / 60);
      relativeDate = showFullText
        ? `${minutes} min${minutes > 1 ? "s" : ""} ago`
        : `${minutes}m ago`;
    } else if (seconds < 86400) {
      const hours = Math.round(seconds / 3600);
      relativeDate = showFullText
        ? `${hours} hour${hours > 1 ? "s" : ""} ago`
        : `${hours}h ago`;
    } else if (seconds < 604800) {
      const days = Math.round(seconds / 86400);
      relativeDate = showFullText
        ? `${days} day${days > 1 ? "s" : ""} ago`
        : `${days}d ago`;
    } else if (seconds < 2592000) {
      const weeks = Math.round(seconds / 604800);
      relativeDate = showFullText
        ? `${weeks} week${weeks > 1 ? "s" : ""} ago`
        : `${weeks}w ago`;
    } else if (seconds < 31536000) {
      const months = Math.round(seconds / 2592000);
      relativeDate = showFullText
        ? `${months} month${months > 1 ? "s" : ""} ago`
        : `${months}mo ago`;
    } else {
      relativeDate = `${getRelativeDateInyears({ seconds, showFullText })} ago`;
    }
  }
  return relativeDate;
}

export const getFormattedDateWithTimezone = (date: string) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateInUserTimezone = new Date(date);
  const formattedDate = formatInTimeZone(
    dateInUserTimezone,
    userTimezone,
    "MMM d, yyyy h:mmaaaa zzz"
  );
  return formattedDate;
};

export const formatDate = (
  inputDate: string | null | undefined
): string | null => {
  if (!inputDate) {
    return null;
  }
  const date = new Date(inputDate);
  return format(date, "yyyy-MM-dd");
};

const getRelativeDateInyears = ({
  seconds,
  showFullText,
}: {
  seconds: number;
  showFullText: boolean;
}) => {
  const totalYears = parseFloat((seconds / 31536000).toFixed(1));
  const years = Math.floor(totalYears);
  const decimalYears = totalYears - years;
  const months = Math.round(decimalYears * 12);

  let relativeDate;
  if (showFullText) {
    if (months === 0) {
      relativeDate = `${years} year${years !== 1 ? "s" : ""}`;
    } else if (years === 0) {
      relativeDate = `${months} month${months !== 1 ? "s" : ""}`;
    } else {
      relativeDate = `${years} year${years !== 1 ? "s" : ""} ${months} month${
        months !== 1 ? "s" : ""
      }`;
    }
  } else {
    if (months === 0) {
      relativeDate = `${years}y`;
    } else {
      relativeDate = `${years}y ${months}m`;
    }
  }
  return relativeDate;
}
