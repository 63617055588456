import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import Image from "next/legacy/image";
import Link from "next/link";

export function Footer() {
  return (
    <footer className="bg-slate-900 relative overflow-hidden">
      <div className="flex absolute left-1/2 min-w-[420px] sm:w-[650px] h-[340px] sm:h-[520px] top-0 -translate-x-1/2 select-none z-[1]">
        <Image
          className="object-cover"
          src="/footer_ellipse.svg"
          alt="logo"
          width={650}
          height={500}
          unoptimized
        />
      </div>
      <div className="flex w-[420px] sm:w-[650px] h-[340px] sm:h-[520px] absolute -left-40 -sm:left-48 bottom-0 -rotate-90 select-none z-[1]">
        <Image
          className="object-cover"
          src="/footer_ellipse.svg"
          alt="logo"
          width={650}
          height={500}
          unoptimized
        />
      </div>
      <div className="flex w-[420px] sm:w-[650px] h-[340px] sm:h-[520px] absolute -right-44 -bottom-12 rotate-180 select-none z-[1]">
        <Image
          className="object-cover"
          src="/footer_ellipse.svg"
          alt="logo"
          width={650}
          height={500}
          unoptimized
        />
      </div>
      <div className="flex flex-col items-center w-full max-w-[1216px] mx-auto px-7 py-11 sm:py-16 text-gray-300 text-sm relative z-[2]">
        <div className="flex flex-col items-center gap-y-9">
          <Link href="/" className="flex" legacyBehavior>
            <Image src="/logo_v2.svg" alt="logo" width={40} height={40} />
          </Link>
          <div className="space-x-4">
            <Link href="/sponsors">
              I'm a sponsor{" "}
              <FontAwesomeIcon
                className="mt-[1px] text-sm group-hover:translate-x-1 transition-transform"
                icon={faArrowRight}
              />
            </Link>
            <Link href="/investors">
              I'm an investor{" "}
              <FontAwesomeIcon
                className="mt-[1px] text-sm group-hover:translate-x-1 transition-transform"
                icon={faArrowRight}
              />
            </Link>
          </div>

          <div className="flex items-center gap-x-6">
            <a
              className="flex"
              href="https://twitter.com/elmbaseinc"
              target="_blank"
              rel="noreferrer"
            >
              <Image src="/twitter.svg" alt="twitter" width={20} height={20} />
            </a>
            <a
              className="flex"
              href="https://www.linkedin.com/company/elmbase"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/linkedin_gray.svg"
                alt="twitter"
                width={20}
                height={20}
              />
            </a>
          </div>
        </div>
        <div className="w-full flex flex-col-reverse sm:flex-row justify-between items-center text-center gap-y-6 py-7 sm:py-10 mt-11 sm:mt-16 border-y border-gray-300/30">
          <p>Copyright © 2024 Elmbase. All rights reserved.</p>
          <div className="flex items-center gap-x-6">
            <Link href="/privacy_policy" className="hover:text-gray-50">
              Privacy Policy
            </Link>
            <Link href="/terms_of_service" className="hover:text-gray-50">
              Terms of Service
            </Link>
          </div>
        </div>
        <p className="flex flex-col gap-y-5 text-center text-xs mt-10 w-full max-w-[870px] mx-auto">
          <span>
            Elmbase does not make any representation regarding the suitability
            of the opportunities that appear on the platform.
          </span>
          <span>
            Nothing on the platform should be construed as investment, business,
            legal, or tax advice or constitutes an offer for service or
            assistance with investment in real estate assets. The listing of
            details relating to the opportunities on the platform or any content
            contained on the platform does not constitute an offer by Elmbase to
            sell, solicit or make an offer to participate in the opportunities.
            No information or content available on the platform should be
            construed as a solicitation of an offer to buy or sell any property.
          </span>
          <span>
            Any decision to make direct or indirect investments in real estate
            assets involves significant risks, including the risk of loss of
            capital, and all such decisions should be made only after seeking
            independent legal, business, and tax advice. No Governmental Agency
            nor Elmbase guarantees or assures any returns to any person using
            the platform.
          </span>
        </p>
      </div>
    </footer>
  );
}
