import { Fragment, forwardRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faUser, IconDefinition } from "@fortawesome/free-regular-svg-icons";
import {
  faGear,
  faEnvelopes,
  faChartPie,
} from "@fortawesome/pro-regular-svg-icons";
import { useGlobalState } from "www/shared/modules/global_context";
import Link from "next/link";
import React from "react";
import Avatar from "../Avatar";
import Image from "next/image";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

interface NavLinkProps {
  label: string;
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
  icon: IconDefinition;
  openInNewTab: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function DropdownMenu({
  logout,
  setIsPlatinumStatusModalOpen,
}: {
  logout: () => void;
  setIsPlatinumStatusModalOpen: (isOpen: boolean) => void;
  setIsNotificationSettingsSlideOverOpen?: (isOpen: boolean) => void;
}) {
  const { userProfile, sponsorDeals } = useGlobalState();

  let generalNavLinks = [
    {
      label: "Go to Profile",
      url: `/p/${userProfile?.handle}`,
      icon: faUser,
      openInNewTab: false,
    },
    // {
    //   label: "Make a feature suggestion",
    //   url: "https://elmbase.canny.io/feature-requests",
    //   icon: faCommentCheck,
    //   openInNewTab: true,
    // },
    {
      label: "Deal Dashboard",
      url: "/dashboard/considering",
      icon: faChartPie,
      openInNewTab: false,
    },
    {
      label: "Email Campaigns",
      url: "/account/campaigns/contacts",
      icon: faEnvelopes,
      openInNewTab: false,
    },
  ];

  const adminDashboardLink = {
    label: "Admin Dashboard",
    url: "/account/deals",
    icon: faChartPie,
    openInNewTab: false,
  };

  const settingsLink = {
    label: " Account Settings",
    url: "/account",
    icon: faGear,
    openInNewTab: false,
  };

  const navLinks =
    sponsorDeals.length > 0
      ? [...generalNavLinks, adminDashboardLink, settingsLink]
      : [...generalNavLinks, settingsLink];

  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div>
        <Menu.Button className="inline-flex w-full justify-center focus:outline-none ">
          <div className="flex flex-col items-center gap-0.5 w-20">
            <UserAvatar
              profilePicUrl={userProfile?.profile_pic_url!}
              firstName={userProfile?.first_name!}
              lastName={userProfile?.last_name!}
              isShowingIcon={false}
              size={24}
            />
            <span className="flex justify-center items-center gap-0.5 text-gray-500">
              <span className="text-xs font-normal">Account</span>
              <FontAwesomeIcon
                icon={faChevronDown}
                className="w-[11px] h-[11px]"
              />
            </span>
          </div>
          {/* <FontAwesomeIcon icon={faUserCircle} className="text-gray-500 rounded-full w-[32px] h-[32px]" /> */}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-[241px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none">
          <div className="pt-6 flex justify-center flex-col border-b-1  border-b-gray-200">
            <div className="w-full text-center flex items-center justify-center">
              <Avatar
                src={userProfile?.profile_pic_url!}
                size={70}
                firstName={userProfile?.first_name!}
                lastName={userProfile?.last_name!}
              />
            </div>
            <p className="text-base leading-6 font-medium text-center py-1">
              {userProfile?.first_name} {userProfile?.last_name}
            </p>

            <p className="text-xs leading-4 font-normal text-center pb-2">
              {userProfile?.is_sponsor === true ? "Sponsor" : "Investor"}
            </p>
            {userProfile && userProfile.current_org_id && (
              <div className="flex gap-2 items-center py-3 px-4 cursor-pointer">
                <div
                  className="w-[20px] h-[20px] bg-gradient-to-r from-[#166534] to-[#1BD05F] rounded-full mr-2"
                  style={{
                    background:
                      "linear-gradient(287.2deg, #166534 7.5%, #1BD05F 92.91%)",
                  }}
                ></div>
                {/* <div className="w-[18px] h-[18px] bg-gradient-to-r from-[#166534] to-[#1BD05F] rounded-full"></div> */}
                <Link
                  href={`/organization/${userProfile.current_org_handle}`}
                  legacyBehavior
                >
                  <span className="text-sm leading-5 font-medium text-left">
                    {userProfile?.current_org_name}
                  </span>
                </Link>
              </div>
            )}
            <button
              className="flex py-3 px-4 items-center gap-2 self-stretch border-y border-gray-200 bg-white"
              onClick={() => setIsPlatinumStatusModalOpen(true)}
            >
              <div className="flex p-0.5 items-center gap-2.5 mr-0.5">
                <Image
                  src={"/icons/platinum_status.svg"}
                  alt="Platinum status"
                  width={20}
                  height={20}
                />
              </div>
              <span className="items-start text-coolGray-400 text-sm font-medium">
                {userProfile?.is_platinum_status ? (
                  <>
                    Platinum Status:{" "}
                    <span className="font-semibold text-green-700">Active</span>
                  </>
                ) : (
                  "Earn Platinum status"
                )}
              </span>
            </button>
          </div>
          <div className="">
            <div className=""></div>
            {navLinks.map((link, index) => (
              <Menu.Item key={index}>
                <NavLink
                  href={link.url}
                  label={link.label}
                  icon={link.icon}
                  openInNewTab={link.openInNewTab}
                />
              </Menu.Item>
            ))}
            {/* {setIsNotificationSettingsSlideOverOpen !== undefined && (
              <Menu.Item>
                <NavLink
                  onClick={() => setIsNotificationSettingsSlideOverOpen(true)}
                  label="Dashboard notification settings"
                  icon={faGear}
                  openInNewTab={false}
                />
              </Menu.Item>
            
            )} */}
          </div>
          <SignOutButton logout={logout} />
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => {
    let { href, icon, label, openInNewTab, onClick = null, ...rest } = props;

    const contents = (
      <a
        ref={ref}
        className={classNames(
          "px-4 py-4 text-sm flex gap-2 hover:bg-gray-100 text-gray-700"
        )}
        target={openInNewTab ? "_blank" : undefined}
        {...rest}
      >
        <FontAwesomeIcon
          icon={icon}
          className={
            `text-green-700 text-base font-normal w-5 h-5 mr-2` +
            (label.length > 14 ? " mt-0.5" : "")
          }
        />
        {label}
      </a>
    );

    // if onClick method is given then use normal <a> tag, otherwise use the <Link>
    // make sure cursor pointer
    if (!href)
      return (
        <a className="cursor-pointer" onClick={onClick!}>
          {contents}
        </a>
      );

    return (
      <Link href={href!} legacyBehavior>
        {contents}
      </Link>
    );
  }
);

export const UserAvatar = ({
  profilePicUrl,
  firstName,
  lastName,
  size,
  isShowingIcon=true,
}: {
  profilePicUrl: string;
  firstName: string;
  lastName: string;
  isShowingIcon?: boolean;
  size?: number;
}) => {
  return (
    <div className="flex items-center space-x-[4.5px]">
      <Avatar
        src={profilePicUrl}
        size={size ?? 32}
        firstName={firstName}
        lastName={lastName}
      />
      {isShowingIcon && (
        <span className="text-green-700">
          <svg
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.01411 5.86216C5.28267 6.13071 5.7188 6.13071 5.98735 5.86216L10.1124 1.73716C10.3809 1.4686 10.3809 1.03247 10.1124 0.763917C9.8438 0.495362 9.40767 0.495362 9.13911 0.763917L5.49966 4.40337L1.86021 0.766064C1.59165 0.49751 1.15552 0.49751 0.886963 0.766064C0.618408 1.03462 0.618408 1.47075 0.886963 1.73931L5.01196 5.86431L5.01411 5.86216Z"
              fill="#15803D"
            />
          </svg>
        </span>
      )}
    </div>
  );
};

export const SignOutButton = ({ logout }: { logout: () => void }) => {
  return (
    <div className="border-t-1 border-t-gray-200">
      <Menu.Item>
        <button
          type="submit"
          className={classNames(
            "w-full px-4 py-4 text-sm flex gap-2 flex text-red-700 hover:bg-gray-100 hover:text-red-900"
          )}
          onClick={logout}
        >
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className=" text-base font-normal w-5 h-5 mr-2"
          />
          <p className="text-sm leading-5 font-normal">Sign Out</p>
        </button>
      </Menu.Item>
    </div>
  );
};

NavLink.displayName = "NavLink";
