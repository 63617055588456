import { VariantProps, cva } from "class-variance-authority";
import { FC } from "react";

const spinnerVariants = cva("animate-spin", {
  variants: {
    size: {
      sm: "h-5 w-5",
      md: "h-10 w-10",
      lg: "h-16 w-16",
    },
    color: {
      white: "text-white",
      green: "text-green-700",
  }},
  defaultVariants: {
    size: "sm",
    color: "white",
  },
});

interface SpinnerProps extends VariantProps<typeof spinnerVariants> {}

export const Spinner: FC<SpinnerProps> = ({
  size,
  color,
}) => {
  return (
    <svg className={
      spinnerVariants({
        size,
        color,
      })
    } viewBox="0 0 24 24">
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
