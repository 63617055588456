import { User } from "@supabase/supabase-js";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { Deal, ProfilePageView } from "types/views";
import { UserPreference, UserProfileOverrides } from "types/tables";
import { useRouter } from "next/navigation";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { Database } from "supabase/generated/types";

type OrgPageLastView = {
  orgHandle: string;
  lastViewedAt: Date;
}

interface GlobalState {
  supabaseUser: User | null | undefined;
  userProfile: ProfilePageView | null | undefined;
  userPreferences: UserPreference["Row"] | null | undefined;
  userProfileOverrides: UserProfileOverrides["Row"] | null | undefined;
  isAuthModalOpen: boolean; // this is whether or not the login/signup modal is open, only relevant for when the user isn't logged in
  isUpdateModalOpen: boolean; // this is whether or not the update user modal is open, which gets automatically shown when the user is missing some information
  authModalVariant: "login" | "signup";
  sponsorDeals: Deal[];
  hasJustClosedTour: boolean; // used to show the tooltip for a few seconds
  setGlobalState: (update: Partial<GlobalState>) => void;
  viewedOrgPages: OrgPageLastView[];
}

interface LocalStorageStoreUser {
  hasUserDismissedAccountSetupModal?: boolean;
  sponsorMessageShownDealIds: number[];
  shareTooltipShownDealIds: number[];
  attachmentIdToLastPageViewed: {
    [key: string]: number;
  };
  hasUserDismissedInboxTour: boolean;
}

interface LocalStorageStore {
  users: {
    [key: string]: LocalStorageStoreUser;
  };

  setLocalStorageCurrentUserState: (
    update: Partial<LocalStorageStoreUser>
  ) => void;
  // reset: () => void;
}

export const useGlobalState = create<GlobalState>()(
  devtools((set) => ({
    supabaseUser: undefined,
    userProfile: undefined,
    userPreferences: undefined,
    userProfileOverrides: undefined,
    sponsorDeals: [],
    isAuthModalOpen: false,
    authModalVariant: "signup",
    isUpdateModalOpen: false,
    hasJustClosedTour: false,
    setGlobalState: (update) => set(update),
    viewedOrgPages: [],
  }))
);

const defaultLocalStorageState: LocalStorageStoreUser = {
  hasUserDismissedAccountSetupModal: false,
  sponsorMessageShownDealIds: [],
  shareTooltipShownDealIds: [],
  attachmentIdToLastPageViewed: {},
  hasUserDismissedInboxTour: false,
};

export const useLocalStorageStore = create<LocalStorageStore>()(
  persist(
    (set) => ({
      users: {},
      // ...defaultLocalStorageState,
      setLocalStorageCurrentUserState: (update) => {
        const userId = useGlobalState.getState().userProfile?.user_id;
        if (!userId) {
          return;
        }
        return set((state) => ({
          ...state,
          users: {
            ...state.users,
            [userId]: {
              ...defaultLocalStorageState,
              ...state.users[userId],
              ...update,
            },
          },
        }));
      },
      // reset: () => set(defaultLocalStorageState),
    }),
    {
      name: "global-storage", // unique name,
    }
  )
);

export function useLocalStorageStoreForCurrentUser<
  LocalStorageStoreUserKey extends keyof LocalStorageStoreUser
>(
  field: LocalStorageStoreUserKey
): LocalStorageStoreUser[LocalStorageStoreUserKey] {
  const userId = useGlobalState.getState().userProfile?.user_id;

  return useLocalStorageStore(
    (state) => state.users[userId!]?.[field] || defaultLocalStorageState[field]
  );
}

export const useProcessLogout = () => {
  const { push } = useRouter();
  const clientSupabase = createClientComponentClient<Database>();
  const setGlobalState = useGlobalState((state) => state.setGlobalState);
  const setLocalStorageCurrentUserState = useLocalStorageStore(
    (state) => state.setLocalStorageCurrentUserState
  );

  const processLogout = async () => {
    const { error } = await clientSupabase.auth.signOut();
    setLocalStorageCurrentUserState({
      hasUserDismissedAccountSetupModal: false,
    });
    console.log("Logging out:", error);
    setGlobalState({
      supabaseUser: null,
      userProfile: null,
      userPreferences: null,
    });

    push("/");
  };

  return processLogout;
};
