import Link from "next/link";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { ConnectionRequestInfo } from "types/views";
import { useGlobalState } from "www/shared/modules/global_context";
import { getFullName } from "www/shared/utils";
import {
  acceptConnectionRequest,
  declineConnectionRequest,
} from "./ConnectionRequestCards.fetchers";
import NotificationCardContainer from "./NotificationCardContainer";
import { invalidateNotificationViews } from "./NotificationMenu.fetchers";

function SentYouAFriendRequestCard({
  connectionRequest,
}: {
  connectionRequest: ConnectionRequestInfo;
}) {
  const supabaseUser = useGlobalState((s) => s.supabaseUser);
  const queryClient = useQueryClient();

  const acceptConnectionRequestMut = useMutation(acceptConnectionRequest, {
    onSuccess: () => {
      invalidateNotificationViews(queryClient);
      toast.success(
        `You are now connected with ${getFullName({
          firstName: connectionRequest.first_name ?? "",
          lastName: connectionRequest.last_name,
        })}.`
      );
    },
    onError: (err) => {
      console.log(err);
      toast.error("Something went wrong. Please try again later");
    },
  });

  const declineConnectionRequestMut = useMutation(declineConnectionRequest, {
    onSuccess: () => {
      invalidateNotificationViews(queryClient);
      toast.success(
        `Declined connection request from ${getFullName({
          firstName: connectionRequest.first_name ?? "",
          lastName: connectionRequest.last_name,
        })}.`
      );
    },
    onError: (err) => {
      console.log(err);
      toast.error("Something went wrong. Please try again later");
    },
  });

  return (
    <Link href={`/p/${connectionRequest.handle}`} legacyBehavior>
      <div className="cursor-pointer">
        <NotificationCardContainer
          id={connectionRequest.id}
          profilePicUrl={connectionRequest.profile_pic_url}
          name={getFullName({
            firstName: connectionRequest.first_name ?? "",
            lastName: connectionRequest.last_name,
          })}
          time={connectionRequest.created_at}
          isSeen={connectionRequest.is_seen}
          handle={`/p/${connectionRequest.handle}`}
        >
          <div className="space-y-2">
            <p className="text-sm font-normal text-gray-500">
              Sent you a connection request
            </p>{" "}
            {!connectionRequest.is_connected && (
              <div className="flex gap-x-3">
                <button
                  className="bg-green-700 hover:bg-green-800 text-white px-4 py-2 rounded-md justify-center text-sm font-medium box-border"
                  onClick={(e) => {
                    e.preventDefault();
                    acceptConnectionRequestMut.mutate({
                      user_id_1: connectionRequest.from_user_id,
                      user_id_2: supabaseUser?.id,
                    });
                  }}
                >
                  Accept
                </button>
                <button
                  className="bg-transparent border border-gray-500 text-gray-700 hover:text-green-700 hover:border-green-700 px-4 py-2 rounded-md justify-center text-sm font-medium box-border"
                  onClick={(e) => {
                    e.preventDefault();
                    declineConnectionRequestMut.mutate({
                      user_id_1: connectionRequest.from_user_id,
                      user_id_2: supabaseUser?.id,
                    });
                  }}
                >
                  Decline
                </button>
              </div>
            )}
            {connectionRequest.is_connected && (
              <button
                className="bg-transparent border border-gray-500 text-gray-700 hover:text-green-700 hover:border-green-700 px-4 py-2 rounded-md justify-center text-sm font-medium box-border"
                onClick={() => {
                  // referral ? setOpenAuthModal("signup") : onShare();
                }}
              >
                Connected
              </button>
            )}
          </div>
        </NotificationCardContainer>
      </div>
    </Link>
  );
}

export default function ConnectionRequestCards({
  requestNotifications,
}: {
  requestNotifications: ConnectionRequestInfo[];
}) {
  return (
    <>
      <div>
        {requestNotifications.map((requestNotification, index: number) => {
          return (
            <SentYouAFriendRequestCard
              key={index}
              connectionRequest={requestNotification}
            />
          );
        })}
      </div>
    </>
  );
}
